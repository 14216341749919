/* eslint-disable no-new */
import React, { useEffect, useState } from 'react';
import { Grid2, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Spinner from 'components/Spinner';
import useUrlParams from 'hooks/useUrlParams';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions';

import CustomAutocomplete from './CustomAutocomplete';
import Map from './Map/Map';
import useStyles from './styles';
import Tasks from './Tasks';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import KPIs from './KPIs';
import Typography from '@mui/material/Typography';
import FolderSharedIcon from '@mui/icons-material/FolderOutlined';
import BoxItem from './BoxItem';
import LocationOnIcon from '@mui/icons-material/LocationOnOutlined';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import RootPaper from '../../styles/rootPaper';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';

const StyledToggleButton = styled(ToggleButton)({
  padding: '10px 25px',
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: '#3379FF'
  }
});

const HomeScreen = () => {
  const classes = useStyles();

  const [year, setYear] = useState(new Date().getFullYear());
  const {
    homeData,
    loaded: homeDataLoaded,
  } = useSelector((state) => state.home);
  const { portfolios } = useSelector((state) => state.portfolios);
  const { data: assets } = useSelector((state) => state.assets.assets);
  const { portfolioIds, setPortfolios, assetIds, setAssetIds } = useUrlParams();
  const [period, setPeriod] = React.useState('month');
  const [targetAssetIds, setTargetAssetIds] = React.useState([]);
  const navigate = useHistory();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    dispatch(actions.getHomeData());
    dispatch(actions.getAlertsCount());
  }, []);

  useEffect(() => {
    if (!portfolioIds) {
      return;
    }

    dispatch(actions.getAssets(portfolioIds));
  }, [portfolioIds]);

  useEffect(() => {
    if (JSON.stringify(targetAssetIds) === JSON.stringify(assets.map((item) => item.id))) {
      return;
    }

    setTargetAssetIds(assets.map((item) => item.id));
  }, [assets]);

  useEffect(() => {
    if (JSON.stringify(targetAssetIds) === JSON.stringify(assetIds)) {
      return;
    }

    setTargetAssetIds(assetIds);
  }, [assetIds]);

  useEffect(() => {
    dispatch(actions.getAlertsList([], targetAssetIds));
    if (targetAssetIds.length === 1) {
      dispatch(actions.getHomeData([], targetAssetIds));
    } else {
      dispatch(actions.getHomeData(portfolioIds, []));
    }
  }, [targetAssetIds]);

  if (!homeDataLoaded) {
    return <Spinner />;
  }

  return (
    <Container maxWidth="xl" className={classes.paddingX}>
      <br />
      <Typography variant="h4">Welcome back! Let’s make today great</Typography>
      <Grid2 container sx={{ padding: '20px 0' }} spacing={5}>
        <Grid2 item size={6}>
          <CustomAutocomplete
            items={portfolios}
            updateUrlParams={setPortfolios}
            urlParams={portfolioIds}
            label="Portfolios"
            placeholder="Portfolios"
            disabled={!!assetIds.length}
          />
        </Grid2>
        <Grid2 item size={6}>
          <CustomAutocomplete
            items={assets}
            updateUrlParams={(values) => {
              setDisabled(true)
              setTimeout(() => setDisabled(false), 1000)
              setAssetIds(values);
            }}
            urlParams={assetIds}
            label="Assets"
            placeholder="Assets"
            disabled={disabled}
          />
        </Grid2>
      </Grid2>
      <RootPaper sx={{ padding: '16px' }}>
        <Grid2 container spacing={5}>
          <Grid2 item container spacing={3} size={6}>
            <Grid2 size={4}>
              <BoxItem
                name="Portfolios"
                to="/Portfolios"
                count={homeData.portfolioCount}
                icon={FolderSharedIcon}
              />
            </Grid2>
            <Grid2 size={4}>
              <BoxItem name="Assets" to="/Assets" count={homeData.assetCount} icon={LocationOnIcon} />
            </Grid2>
            <Grid2 size={4}>
              <BoxItem name="Tenants" to="/Tenants" count={homeData.tenantCount} icon={PeopleIcon} />
            </Grid2>
            <Grid2 size={12}>
              <Tasks />
            </Grid2>
          </Grid2>
          <Grid2 item size={6}>
            <Map
              assets={assets}
              defaultZoom={3}
              mapType="hybrid"
            />
          </Grid2>
        </Grid2>
      </RootPaper>
      <Divider sx={{ my: 4 }} />
      <Grid2 container spacing={5} columns={{lg: 16, xl: 12}} sx={{alignItems: 'center'}}>
        <Grid2 item size={{lg: 3, xl: 2}} offset={{lg: 7, xl: 6}} sx={{display: 'flex'}}>
        <Button
          variant="contained"
          onClick={() => navigate.push('/BaseRent/Details/' + targetAssetIds[0])}
          sx={{
            borderRadius: '100px',
            padding: '12px',
            width: '100%',
            backgroundColor: '#3379FF',
          }}
          disableRipple>
          View all Reports
        </Button>
        </Grid2>
        <Grid2 item size={{lg: 3, xl: 2}} sx={{display: 'flex'}}>
          <ToggleButtonGroup
            color="primary"
            value={period}
            exclusive
            onChange={(_, value) => setPeriod(value)}
            aria-label="Platform"
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <StyledToggleButton value="month">Monthly</StyledToggleButton>
            <StyledToggleButton value="year">Annual</StyledToggleButton>
          </ToggleButtonGroup>
        </Grid2>
        <Grid2 item size={{lg: 3, xl: 2}}>
          <DatePicker label={'Year'} openTo="year" onChange={(value) => setYear(value.getFullYear())} views={['year']}
                      maxDate={new Date()}
                      minDate={new Date(2018, (new Date()).getMonth(), (new Date()).getDate())}
                      value={new Date(year, 1, 1, 0, 0, 0)} />
        </Grid2>
      </Grid2>
      <KPIs
        portfolioIds={portfolioIds}
        assetIds={assetIds}
        period={period}
        year={year}
        firstAsset={targetAssetIds[0]}
      />
      <Divider sx={{ my: 4 }} />
    </Container>
  );
};

export default HomeScreen;
