import React from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import CancelButton from 'components/Buttons/CancelButton';
import SaveButton from 'components/Buttons/SaveButton';
import DatePicker from 'components/DatePicker/KeyboardPicker';
import MultiSelectFilter from 'components/Filters/MultiSelectFilter';
import Transition from 'components/Transition';
import differenceInYears from 'date-fns/differenceInYears';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useStyles from 'styles/forms';

const getForm = (form) => ({
  id: form?.id || '',
  unitidsList: form?.unitidsList ? form.unitidsList : [],
  number: form?.number ? form.number : '',
  startdate: form?.startdate ? form.startdate : null,
  enddate: form?.enddate ? form.enddate : null
});

const AddContract = ({ details, open, handleClose, handleAdd, units, label }) => {
  const classes = useStyles();
  const [form, setForm] = React.useState({});
  const loading = useSelector((state) => state.leasingManager.loading);

  const handleSave = () => {
    if (!form.unitidsList || !form.number || !form.startdate || !form.enddate) {
      return toast.error('Please fill in the form!');
    }

    if (new Date(form.startdate) > new Date(form.enddate)) {
      return toast.error('Start Date needs to be before End Date');
    }

    if (Math.abs(differenceInYears(new Date(form.startdate), new Date(form.enddate))) > 15) {
      return toast.error('Contract Dates should be less than 15 years difference');
    }

    return handleAdd(form);
  };

  const handleChange = (name, value) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCancel = () => {
    setForm(getForm(details));
    handleClose();
  };

  React.useEffect(() => {
    setForm(getForm(details));
  }, [details]);

  return (
    (<Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-contract-Title"
      keepMounted
      TransitionComponent={Transition}
      classes={{ paper: classes.paper }}
      maxWidth="xl"
    >
      <Box
        bgcolor="#2C324D"
        p={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        className={classes.responsive}
      >
        <Typography className={classes.assetTitle}>Create Contract</Typography>
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
      </Box>
      <DialogContent>
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <MultiSelectFilter
            items={units}
            urlParams={form.unitidsList}
            setUrlParams={(e) => handleChange('unitidsList', e)}
            label={label}
            keyword="name"
            large
          />

          <TextField
            fullWidth
            label="Number/Name"
            variant="outlined"
            className={classes.textField}
            value={form.number}
            disabled={loading}
            onChange={(e) => handleChange('number', e.target.value)}
            slotProps={{
              input: {
                classes: {
                  input: classes.input
                }
              }
            }}
          />

          <Box mt={3}>
            <DatePicker
              format="DD/MM/YYYY"
              selectedDate={form.startdate}
              setSelectedDate={(e) => handleChange('startdate', e)}
              label="Term Start Date"
            />
          </Box>

          <Box mt={3}>
            <DatePicker
              format="DD/MM/YYYY"
              selectedDate={form.enddate}
              minDate={form.startdate}
              setSelectedDate={(e) => handleChange('enddate', e)}
              label="Term End Date"
              minErrorText="Date cannot be before term start date"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={2}>
          <CancelButton onClick={handleCancel} />
          <SaveButton onClick={handleSave} loading={loading} />
        </Box>
      </DialogActions>
    </Dialog>)
  );
};

export default React.memo(AddContract);

AddContract.defaultProps = {
  details: {}
};

AddContract.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  units: PropTypes.arrayOf(PropTypes.any).isRequired,
  details: PropTypes.objectOf(PropTypes.any),
  label: PropTypes.string.isRequired
};
