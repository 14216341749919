import React, {useEffect, useState} from 'react';

import {AgGridReact} from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css"; // Mandatory CSS required by the grid
import 'ag-grid-enterprise';
import PropTypes from 'prop-types';

import ConditionalLink from "../../../components/ConditionalLink";
import {formatNumberToCurrency} from "../../../helpers/utils";

const getUrl = (managerID = '', name, assetId, tab) => {
    const urls = {
        BaseRent: `/Tenants/${managerID}/Details`,
        TurnoverRent: `/Tenants/${managerID}/Details`,
        ServiceCharge: `/Tenants/${managerID}/Details`,
        CumulativeRent: `/Tenants/${managerID}/Details`,
        OtherIncome: `/Tenants/${managerID}/Details`,
        OtherExpenses: `/Tenants/${managerID}/Details`,
        NOI: `/Tenants/${managerID}/Details`,
        LeasingFee: `/Tenants/${managerID}/Details`,
        FitOut: `/Tenants/${managerID}/Details`,
        Opex: `/Asset/${assetId}/OpexManager/${managerID}`,
        Capex: `/Asset/${assetId}/CostManager/${managerID}`,
        Actuals: `/Asset/${assetId}/Actuals`
    };

    return name === 'forecastsList' ? urls[tab] : urls.Actuals;
};

function currencyFormatter(params) {
    return params.value === 0 ? '-' : formatNumberToCurrency(params.value, 'EUR', '€0', 0);
}

const TableActualsForecasts = ({data, name, tab, assetId}) => {
    useEffect(() => {
        let defaultColumns = [
            {
                field: 'name',
                headerName: tab === 'NOI' || tab === 'Opex' || tab === 'Capex' || tab === 'OtherExpenses' || tab === 'OtherIncome' ? 'Item' : 'Tenant',
                cellRenderer: (props) => {
                    if (props.data === undefined) return null;

                    return (
                      tab !== 'NOI' &&
                      <ConditionalLink to={getUrl(props.data.managerid, name, assetId, tab)} from="Tenants" condition>
                          {props.data.name}
                      </ConditionalLink> || props.data.name
                    );
                },
                filter: 'agSetColumnFilter'
            },
            ...new Array(12).fill().map((e, i) => {
                let d = new Date()
                d.setMonth(i)
                return {
                    field: i.toFixed(0), headerName: d.toLocaleString('en-US', {month: 'short'}),
                    cellRenderer: currencyFormatter,
                    filter: 'agNumberColumnFilter',
                }
            })
        ];
        if (tab !== 'CumulativeRent' && tab !== 'Cumulative Rent') {
            defaultColumns.splice(1, 0, {
                headerName: 'Total',
                cellStyle: {backgroundColor: 'lightgrey'},
                valueFormatter: (params) => {
                    let total = 0;
                    for (let i = 0; i < 12; i++) {
                        total += params.data[i];
                    }

                    return formatNumberToCurrency(total, 'EUR', '-', 0);
                }
            })
        }

        setColDefs(defaultColumns);
    }, [tab]);

        let defaultColumns = [
            {
                field: 'name',
                headerName: tab === 'NOI' || tab === 'Opex' || tab === 'Capex' || tab === 'OtherExpenses' || tab === 'OtherIncome' ? 'Item' : 'Tenant',
                cellRenderer: (props) => {
                    if (props.data === undefined) return null;

                    return ( tab !== 'NOI' &&
                        <ConditionalLink to={getUrl(props.data.managerid, name, assetId, tab)} from="Tenants" condition>
                            {props.data.name}
                        </ConditionalLink> || props.data.name
                    );
                },
                filter: 'agSetColumnFilter'
            },
            ...new Array(12).fill().map((e, i) => {
                let d = new Date()
                d.setMonth(i)
                return {
                    field: i.toFixed(0), headerName: d.toLocaleString('en-US', {month: 'short'}),
                    cellRenderer: currencyFormatter,
                    filter: 'agNumberColumnFilter',
                }
            })
        ];
        if (tab !== 'CumulativeRent') {
            defaultColumns.splice(1, 0, {
                headerName: 'Total',
                cellStyle: {backgroundColor: 'lightgrey'},
                valueFormatter: (params) => {
                    let total = 0;
                    for (let i = 0; i < 12; i++) {
                        total += params.data[i];
                    }

                    return formatNumberToCurrency(total, 'EUR', '-', 0);
                }
            })
        }

        const [colDefs, setColDefs] = useState(defaultColumns);

        const autoSizeStrategy = {
            type: 'fitCellContents',
            // defaultMinWidth: 100,
        };
        return (
            <div
                className="ag-theme-quartz" // applying the grid theme
                style={{height: 500, padding: '20px'}} // the grid will fill the size of the parent container
            >
                <AgGridReact
                    columnDefs={colDefs}
                    rowData={data.map((item) => {
                        if (item.actualsList) {
                            return {...item.actualsList, managerid: item.managerid, name: item.name, totals: item.totals}
                        }

                        return {...item.forecastsList, managerid: item.managerid, name: item.name, totals: item.totals}
                    })}
                    enableRangeSelection={true}
                    statusBar={{
                        statusPanels: [
                            {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
                            {
                                statusPanel: 'agAggregationComponent', statusPanelParams: {
                                    aggFuncs: ['avg', 'sum']
                                }
                            },
                        ]
                    }}
                    autoSizeStrategy={autoSizeStrategy}
                    getRowStyle={(params) => {
                        if (params.node.data.name === 'Total Income' || params.node.data.name === 'NOI') {
                            return { background: 'lightgrey' };
                        }
                    }}
                />
            </div>
        );
    }
;

export default TableActualsForecasts;

TableActualsForecasts.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string.isRequired,
    tab: PropTypes.string.isRequired,
    assetId: PropTypes.string.isRequired
};

TableActualsForecasts.defaultProps = {
    data: []
};
