import {ListDocumentsRequest, DeleteDocumentRequest, CreateDocumentRequest} from 'api/document_grpc_web_pb';
import {metaData} from 'config/config';
import {DocumentService} from '../services/document';
import {
    ConditionValueGorm,
    EqualConditionGorm,
    FilterCondition,
    Filters
} from 'api/filters_gorm_pb';
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb';

import * as actionTypes from './actionTypes';
import {formatISO} from "date-fns";

const getDocumentDataStart = () => ({
    type: actionTypes.GET_DOCUMENT_DATA_START
});

const getDocumentDataSuccess = (data) => ({
    type: actionTypes.GET_DOCUMENT_DATA_SUCCESS,
    data
});

const getDocumentDataFail = (error) => ({
    type: actionTypes.GET_DOCUMENT_DATA_FAIL,
    error
});

export const createDocument = (assetID, tenantID, documentType, fileType, path) => () => {
    const request = new CreateDocumentRequest();
    if (assetID) {
        const assetIDWrapper = new wrappers.StringValue();
        assetIDWrapper.setValue(assetID);
        request.setAssetid(assetIDWrapper);
    }
    if (tenantID) {
        const tenantIDWrapper = new wrappers.StringValue();
        tenantIDWrapper.setValue(tenantID);
        request.setTenantid(tenantIDWrapper);
    }
    request.setDocumenttype(documentType);
    request.setFiletype(fileType);
    request.setPath(path);

    return new Promise((resolve, reject) => {
        DocumentService.createDocument(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject());
            }
        });
    });
};

export const getDocumentData = (assetID, tenantID) => (dispatch) => {
    dispatch(getDocumentDataStart());
    const request = new ListDocumentsRequest();
    const filters = new Filters();
    let list = [];
    if (assetID) {
        const filterCondition = new FilterCondition();
        const equalCondition = new EqualConditionGorm();
        equalCondition.setField('AssetID');
        const conditionValue = new ConditionValueGorm();
        conditionValue.setStringvalue(assetID);
        equalCondition.setValue(conditionValue);
        filterCondition.setEqual(equalCondition);
        list.push(filterCondition);
    }
    if (tenantID) {
        const filterCondition = new FilterCondition();
        const equalCondition = new EqualConditionGorm();
        equalCondition.setField('TenantID');
        const conditionValue = new ConditionValueGorm();
        conditionValue.setStringvalue(tenantID);
        equalCondition.setValue(conditionValue);
        filterCondition.setEqual(equalCondition);
        list.push(filterCondition);
    }

    filters.setFilterconditionsList(list);
    request.setFilters(filters);

    return new Promise((resolve, reject) => {
        DocumentService.listDocuments(request, metaData(), (err, response) => {
            if (err) {
                dispatch(getDocumentDataFail(err.message));
                reject(err.message);
            } else {
                dispatch(getDocumentDataSuccess(response.toObject()));
                resolve(response.toObject());
            }
        });
    });
};

export const deleteDocument = (ids) => () => {
    const request = new DeleteDocumentRequest();
    request.setIdList(ids);

    return new Promise((resolve, reject) => {
        DocumentService.deleteDocument(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject());
            }
        });
    });
};
