import React from 'react';

import UserHasAccess from 'hooks/UserHasAccess';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import AdvancedReporting from 'views/AdvancedReporting';
import Assets from 'views/Assets';
import AssetDetails from 'views/Assets/AssetsDetails';
import HomeScreen from 'views/HomeScreen';
import ChartDetails from 'views/HomeScreen/ChartDetails';
import Portfolios from 'views/Portfolios';
import Tenants from 'views/Tenants';
import TenantDetailsPage from 'views/Tenants/TenantDetails';
import Users from 'views/Users';
import UsersEdit from 'views/Users/UsersEdit';
import LeasingContract from 'Workflows/Tenant/LeasingManager/LeasingManagerTabs/LeasingContract';

import isAdmin from '../helpers/isAdmin';
import Organisations from '../views/Organisations';
import AssetCostManager from '../Workflows/Asset/AssetCostManager';
import AssetLegalTracker from '../Workflows/Asset/LegalTracker';
import Dashboard from '../Workflows/Asset/Reports/Dashboard/Dashboard';
import Valuations from '../Workflows/Asset/Valuations';
import FacilityManager from '../Workflows/Property/FacilityManager';
import OpexManager from '../Workflows/Property/OpexManager';
import TenantManager from '../Workflows/Property/TenantManager';
import TenantManagerDetails from '../Workflows/Property/TenantManager/TenantManagerDetails';
import CashManager from '../Workflows/Shared/CashManager';
import AssetCashManager from '../Workflows/Shared/CashManager/AssetCashManager';
import LegalTracker from '../Workflows/Tenant/LegalTracker';
import LegalTrackerDetails from '../Workflows/Tenant/LegalTracker/LegalTrackerDetails';
import CostManager from '../Workflows/Tenant/TenantCostManager';
import CostManagerFlow from '../Workflows/Tenant/TenantCostManager/TenantCostManagerFlow';
import AssetsTenantDetails from "../views/Assets/AssetsDetails/AssetsTenantDetails";
import Box from '@mui/material/Box';

const dashRoutes = [
  {
    path: '/',
    exact: true,
    name: 'Home',
    component: HomeScreen,
    rights: 'asset'
  },
  {
    path: '/:tab(BaseRent|TurnoverRent|ServiceCharge|OtherIncome|CumulativeRent|Opex|Capex|OtherExpenses|NOI|LeasingFee|FitOut|MLA)/Details/:assetId',    exact: true,
    name: 'ChartDetails',
    component: ChartDetails,
    rights: 'asset'
  },
  {
    path: '/Portfolios',
    exact: true,
    name: 'Portfolios',
    component: Portfolios,
    rights: 'portfolio'
  },
  {
    path: '/ADA',
    exact: true,
    name: 'ADA',
    component: Box,
    rights: 'asset'
  },
  {
    path: '/Assets',
    exact: true,
    name: 'Assets',
    component: Assets,
    rights: 'asset'
  },
  {
    path: '/AdvancedReporting',
    exact: true,
    name: 'AdvancedReporting',
    component: AdvancedReporting,
    rights: 'asset'
  },
  {
    path: '/Tenants',
    exact: true,
    name: 'Tenants',
    component: Tenants,
    rights: 'tenant'
  },
  {
    path: '/Users',
    exact: true,
    name: 'Users',
    component: Users,
    rights: 'asset'
  },
  {
    path: '/Users/:id',
    exact: true,
    name: 'UsersEdit',
    component: UsersEdit,
    rights: 'asset'
  },
  {
    path: '/Asset/:id/:tab(Details|Tenants|OpexManager|FacilityManager|Turnover|Actuals|Valuations|CostManager|CashManager|LegalTracker|Reports|AdvancedReporting|Documents)/:index?',
    exact: true,
    name: 'AssetDetails',
    component: AssetDetails,
    rights: 'asset'
  },
  {
    path: '/Asset/:assetID/Tenant/:id',
    exact: true,
    name: 'AssetTenantDetails',
    component: AssetsTenantDetails,
    rights: 'asset'
  },
  {
    path: '/Tenants/:id/:tab(Details|LeasingStrategy|Options|Turnover|ContractClauses|Reports|TenantManager|CostManager)',
    exact: true,
    name: 'Tenants',
    component: TenantDetailsPage,
    rights: 'asset'
  },
  {
    path: '/Tenants/:id/LeasingManager/ContractDetails',
    exact: true,
    name: 'ContractDetails',
    component: LeasingContract,
    rights: 'asset'
  },
  {
    path: '/Tenants/:id/CostManager',
    exact: true,
    name: 'CostManager',
    component: CostManager,
    rights: 'asset'
  },
  {
    path: '/Tenants/:id/CostManager/:index',
    exact: true,
    name: 'CostManagerFlow',
    component: CostManagerFlow,
    rights: 'asset'
  },
  {
    path: '/Tenants/:id/CashManager',
    exact: true,
    name: 'CashManager',
    component: CashManager,
    rights: 'asset'
  },
  {
    path: '/Tenants/:id/TenantManager',
    exact: true,
    name: 'TenantManager',
    component: TenantManager,
    rights: 'asset'
  },
  {
    path: '/Tenants/:id/LegalTracker',
    exact: true,
    name: 'LegalTracker',
    component: LegalTracker,
    rights: 'asset'
  },
  {
    path: '/Asset/:id/LegalTracker',
    exact: true,
    name: 'AssetLegalTracker',
    component: AssetLegalTracker,
    rights: 'asset'
  },
  {
    path: '/Asset/:id/CostManager',
    exact: true,
    name: 'AssetCostManager',
    component: AssetCostManager,
    rights: 'asset'
  },
  {
    path: '/Asset/:id/OpexManager',
    exact: true,
    name: 'OpexManager',
    component: OpexManager,
    rights: 'asset'
  },
  {
    path: '/Tenants/:id/LegalTracker/:index',
    exact: true,
    name: 'LegalTrackerDetails',
    component: LegalTrackerDetails,
    rights: 'asset'
  },
  {
    path: '/Asset/:id/CashManager',
    exact: true,
    name: 'AssetCashManager',
    component: AssetCashManager,
    rights: 'asset'
  },
  {
    path: '/Asset/:id/FacilityManager',
    exact: true,
    name: 'FacilityManager',
    component: FacilityManager,
    rights: 'asset'
  },
  {
    path: '/Asset/:id/Valuations',
    exact: true,
    name: 'Valuations',
    component: Valuations,
    rights: 'asset'
  },
  {
    path: '/Asset/:id/AdvancedReporting',
    exact: true,
    name: 'AdvancedReporting',
    component: AdvancedReporting,
    rights: 'asset'
  },
  {
    path: '/Tenants/:id/TenantManager/:index',
    exact: true,
    name: 'TenantManagerDetails',
    component: TenantManagerDetails,
    rights: 'asset'
  },
  {
    path: '/Asset/:id/Reports',
    exact: true,
    name: 'Dashboard',
    component: Dashboard,
    rights: 'asset'
  },
  {
    path: '/Organisations',
    exact: true,
    name: 'Organisations',
    component: Organisations,
    rights: 'asset'
  }
];

const Routes = ({ userPermissions }) => (
  <Switch>
    {dashRoutes.map(
      ({ path, exact, name, component, rights }) =>
        UserHasAccess(rights, 'view', userPermissions) && (
          <Route key={name} path={path} exact={exact} name={name} component={component} />
        )
    )}

    <Redirect from="*" to={isAdmin() ? '/Users' : '/'} />
  </Switch>
);
export default Routes;

Routes.propTypes = {
  userPermissions: PropTypes.arrayOf(PropTypes.object).isRequired
};
