import React, {Suspense, useEffect, useState} from 'react';

import Badge from '@mui/material/Badge';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import {Notifications} from '@mui/icons-material';
import Breadcrumps from 'components/Breadcrumps';
import Container from 'components/Container';
import Spinner from 'components/Spinner';
import {formatZillions} from 'helpers/utils';
import useUrlParams from 'hooks/useUrlParams';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from 'store/actions';
import useStyles from 'styles/tables';
import Grow from "@mui/material/Grow";
import useRouter from "../../hooks/useRouter";
import clsx from "clsx";
import {Box} from "@mui/material";
import AGGridTable from "../AGGrid";
import {toast} from "react-toastify";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import {openModal} from "../../components/Modal/modalReducer";

const useLocalStyles = makeStyles((theme) => ({
    icon: {
        color: '#CCCCCC'
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 500,
        padding: theme.spacing(6)
    },
    badge: {
        minWidth: theme.spacing(2.2),
        height: theme.spacing(2.2),
        width: theme.spacing(2.2)
    }
}));

const Portfolio = () => {
    const classes = useStyles();
    const localClasses = useLocalStyles();
    const {portfolios, error} = useSelector((state) => state.portfolios);
    const dispatch = useDispatch();
    const router = useRouter();

    useEffect(() => {
        dispatch(actions.getPortfolios());
    }, []);

    const [colDefs, setColDefs] = useState([
        {
            headerName: '',
            field: 'rowSelection',
            checkboxSelection: true,
            headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true,
            maxWidth: 60
        },
        {
            id: 'name',
            headerName: 'Portfolios',
            field: 'name',
            filter: 'agSetColumnFilter',
            cellRenderer: (params) => `${params.value} ${params.data.assigned ? '' : '(unassigned)'}`,
            chartDataType: 'category'
        },
        {headerName: 'No. of Assets', field: 'assetcount', filter: 'agNumberColumnFilter', chartDataType: 'series'},
        {
            headerName: 'Leased Area (m2)',
            field: 'glatotal',
            filter: 'agNumberColumnFilter',
            cellRenderer: (params) => formatZillions(params.value), chartDataType: 'series'
        },
        {
            headerName: 'Notifications',
            field: 'notifs',
            cellRenderer: (params) => (
                <Badge
                    badgeContent={params.data.alertcount}
                    color="error"
                    overlap="circular"
                    classes={{badge: localClasses.badge}}
                    onClick={(e) => {
                        e.stopPropagation();

                        dispatch(
                            actions.setAlertFilters({
                                asset: '',
                                portfolio: params.data.id,
                                tenant: ''
                            })
                        );

                        dispatch(actions.toggleAlertMenu());
                    }}
                >
                    <Notifications className={localClasses.icon}/>
                </Badge>
            ),
            chartDataType: 'excluded'
        },
        {
            id: 'actions',
            cellRenderer: (params) => {
                return (
                    <div>
                        <Button
                            className={classes.ctaButton}
                            style={{marginRight: '10px'}}
                            startIcon={<Icon style={{color: '#5C5C5C'}}>edit</Icon>}
                            onClick={() => dispatch(
                                openModal({
                                    modalType: 'PortfolioModal',
                                    modalProps: {portfolio: {name: params.data.name, id: params.data.id}}
                                })
                            )}
                        >
                            Edit
                        </Button>
                    </div>
                );
            },
            chartDataType: 'excluded'
        }
    ]);

    if (error) {
        return (
            <div className={localClasses.root}>
                <Typography color="error" variant="h5">
                    {error}
                </Typography>
            </div>
        );
    }

    return (
        <Container>
            <Suspense fallback={<Spinner mini/>}>
                <Breadcrumps/>
                <Grow in>
                    <Box className={clsx(classes.containerLarge)}>

                        <Box className={classes.header}>
                            <Typography className={classes.title}>{`Portfolios (${portfolios.length})`}</Typography>
                        </Box>
                        <Box className={clsx(classes.table)}>
                            <AGGridTable
                                data={portfolios}
                                colDefs={colDefs}
                                classes={classes}
                                onCellClicked={(e) => {
                                    if (e.column.colId === 'name') { // cell is from non-select column
                                        router.push(`Assets?portfolioIds=${e.data.name}`)
                                    }
                                }}
                                deleteHandler={(selectedRows, api) => {
                                    api.applyTransaction({remove: selectedRows});
                                    for (let i = 0; i < selectedRows.length; i++) {
                                        dispatch(actions.deletePortfolio(selectedRows[i].id))
                                            .then(() => {
                                            })
                                            .catch((err) => toast.error(err));
                                    }
                                }}
                                buttons={[
                                    <Button
                                        className={classes.ctaButton}
                                        style={{marginRight: '10px'}}
                                        startIcon={<Icon style={{color: '#5381EF'}}>add</Icon>}
                                        onClick={() => dispatch(
                                            openModal({
                                                modalType: 'PortfolioModal',
                                                modalProps: {portfolio: {name: ''}}
                                            })
                                        )}
                                    >
                                        NEW
                                    </Button>
                                ]}
                            ></AGGridTable>
                        </Box>
                    </Box>
                </Grow>
            </Suspense>
        </Container>
    );
};

export default Portfolio;
