import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const RootContainer = styled(Box)(
  ({ theme }) => `
  display: flex;
  height: 100%;
  justify-content: space-around;
  [${theme.breakpoints.down('md')}]: {
    flex-direction: column-reverse;
  }
`,
);

export default RootContainer;