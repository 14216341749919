/* eslint-disable function-paren-newline */
import React, { useEffect, Suspense, lazy } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Spinner from 'components/Spinner';
import useRouter from 'hooks/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import * as actions from 'store/actions';
import LeasingTenantDetail from 'Workflows/Tenant/LeasingManager/LeasingManagerTabs/LeasingTenantDetails';

import useStyles from './styles';
import TenantHeader from './TenantHeader';
import TenantSidebar from './TenantSideBar';

const LeasingStrategy = lazy(() =>
  import('Workflows/Tenant/LeasingManager/LeasingManagerTabs/LeasingStrategy')
);
const TenantOptions = lazy(() =>
  import('Workflows/Tenant/LeasingManager/LeasingManagerTabs/LeasingTenantOptions')
);
const ContractClauses = lazy(() =>
  import('Workflows/Tenant/LeasingManager/LeasingManagerTabs/LeasingContractDetails')
);
const Reports = lazy(() => import('./Reports'));
const TenantManager = lazy(() => import('Workflows/Property/TenantManager'));

const routes = [
  { path: '/Tenants/:id/Details', component: LeasingTenantDetail },
  { path: '/Tenants/:id/LeasingStrategy', component: LeasingStrategy },
  { path: '/Tenants/:id/Options', component: TenantOptions },
  { path: '/Tenants/:id/ContractClauses', component: ContractClauses },
  { path: '/Tenants/:id/Reports', component: Reports },
  { path: '/Tenants/:id/TenantManager', component: TenantManager }
];

const TenantDetails = () => {
  const router = useRouter();
  const { query } = router;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { contract: contracts } = useSelector((state) => state.leasingManager);
  const { loading, loaded, error } = useSelector((state) => state.tenants.tenantDetails);

  useEffect(() => {
    if (!query.id) {
      return;
    }

    dispatch(actions.getTenantDetails(query.id)).then((res) => {
      dispatch(actions.getUnits(res.assetid));
      dispatch(actions.getAssetDetails(res.assetid));
    });
    dispatch(actions.getContractById(query.id));
  }, [query.id]);

  useEffect(() => {
    if (!contracts[0]?.id) return;
    dispatch(actions.getTenantLeasingDetails(contracts[0]?.id));
  }, [contracts[0]?.id]);

  if (!loaded || loading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Box m={4}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      <Box maxWidth="210px" minWidth="210px">
        <TenantSidebar />
      </Box>
      <Box width="100%" className={classes.scrollable}>
        <Box className={classes.flexColumn} sx={{padding: '20px'}}>
          <TenantHeader />
          <br />
          <Suspense fallback={<Spinner mini />}>
            <Switch>
              {routes.map((route) => (
                <Route exact path={route.path}>
                  <route.component />
                </Route>
              ))}
            </Switch>
          </Suspense>
        </Box>
      </Box>
    </Box>
  );
};

export default TenantDetails;
