import React, {useEffect, useState} from 'react';

import {Box, TextField} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import DatePicker from 'components/DatePicker/KeyboardPicker';
import {closeModal} from 'components/Modal/modalReducer';
import ModalWrapper from 'components/Modal/ModalWrapper';
import {isValid} from 'helpers/utils';
import useUrlParams from 'hooks/useUrlParams';
import isEqual from 'lodash/isEqual';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import * as actions from 'store/actions';
import useStyles from 'styles/forms';

const makeBundleId = (portfolioIds) => `PortfolioIds${portfolioIds}`;

const getForm = (tenant, details, isEdit, unitid) => ({
    companyname: isEdit ? tenant.companyname : '',
    tradingname: isEdit ? tenant.tradingname : '',
    id: isEdit ? tenant.id : null,
    industry: isEdit ? tenant.industry : '',
    unitidsList: isEdit && details.unitidsList !== undefined ? details.unitidsList : unitid ? [unitid] : [],
    tenantcontractid:
        isEdit && details.tenantcontractid !== undefined ? details.tenantcontractid : '',
    contractstartdate: isEdit ? new Date(tenant.startdate) : new Date(),
    contractenddate: isEdit ? new Date(tenant.enddate) : new Date()
});

const TenantModal = ({unitid, assetid}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.tenants.loading);
    const units = useSelector((state) => state.assets.unit.unitSchedule);
    const {isEdit} = useSelector((state) => state.modals.modalProps);
    const {portfolioIds} = useUrlParams();
    const {details} = useSelector((state) => state.leasingManager.details);
    const {data} = useSelector((state) => state.tenants.tenantDetails);
    const {tenants} = useSelector((state) => state.tenants);
    const [form, setForm] = useState(getForm(data, details, isEdit, unitid));
    const [nameError, setNameError] = useState(false);

    useEffect(() => {
        const bundleId = makeBundleId(portfolioIds);
        dispatch(actions.getTenants(portfolioIds, bundleId, [], true));
        dispatch(actions.getUnitSchedule(assetid));
    }, [portfolioIds]);

    const updateTenant = () =>
        dispatch(actions.updateTenant(form))
            .then(() => {
                dispatch(actions.getTenantDetails(form.id));
                dispatch(actions.getTenantHeader(form.id));
                toast.success('Tenant Updated');
            })
            .catch((err) => {
                toast.error(err);
            })
            .finally(() => {
                dispatch(actions.getTenantsByAssetId(assetid));
                dispatch(closeModal());
            });

    const createTenant = () =>
        dispatch(actions.createTenant(form, assetid))
            .then(() => {
                dispatch(actions.getTenants(portfolioIds, makeBundleId(portfolioIds), [], true));
                toast.success('Tenant Added');
                setForm(getForm());
            })
            .catch((err) => {
                toast.error(err);
            })
            .finally(() => {
                dispatch(actions.getTenantsByAssetId(assetid));
                dispatch(closeModal());
            });

    const handleSave = () => {
        if (!isValid(form.companyname) || !isValid(form.tradingname) || !isValid(form.industry) || nameError) {
            return toast.error('Please fill in the form!');
        }
        return isEdit ? updateTenant() : createTenant();
    };

    const handleChange = (name, value) => {
        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));

        if (name === 'tradingname') {
            if (tenants.tenants.find((element) => element.tradingname === value)) {
                setNameError(true);
            } else {
                setNameError(false);
            }
        }
    };

    return (
        (<ModalWrapper
            size="xl"
            header={isEdit ? 'Edit Tenant' : 'Create Tenant'}
            handleSave={handleSave}
            disabled={isEqual(getForm(data, details, isEdit), form)}
            loading={loading}
            label="Save"
        >
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                <Autocomplete
                    id="units"
                    options={units}
                    getOptionLabel={(option) => option.unitname}
                    className={classes.autocomplete}
                    autoComplete
                    autoHighlight
                    clearOnEscape
                    multiple
                    value={units?.filter((unit) => form.unitidsList.indexOf(unit.unitid) > -1)}
                    onChange={(_, value) => {
                        handleChange(
                            'unitidsList',
                            value.map((val) => val.unitid)
                        );
                    }}
                    renderInput={(params) => <TextField {...params} label="Units" variant="outlined"/>}
                />
                <TextField
                    fullWidth
                    required
                    label="Industry"
                    variant="outlined"
                    className={classes.textField}
                    value={form.industry}
                    onChange={(e) => handleChange('industry', e.target.value)}
                    slotProps={{
                        input: {
                            classes: {
                                input: classes.selectInput
                            }
                        }
                    }}
                />
                <TextField
                    fullWidth
                    required
                    label="Company Name"
                    variant="outlined"
                    className={classes.textField}
                    value={form.companyname}
                    disabled={loading}
                    onChange={(e) => handleChange('companyname', e.target.value)}
                    id="company"
                    slotProps={{
                        input: {
                            classes: {
                                input: classes.input
                            }
                        }
                    }}
                />
                <TextField
                    fullWidth
                    required
                    label="Tenant Contract Reference"
                    variant="outlined"
                    className={classes.textField}
                    value={form.tradingname}
                    disabled={loading}
                    error={nameError}
                    helperText={"Tenant Contract Reference should be unique"}
                    onChange={(e) => handleChange('tradingname', e.target.value)}
                    id="Tenant Contract Reference"
                    slotProps={{
                        input: {
                            classes: {
                                input: classes.input
                            }
                        }
                    }}
                />
                <Box mt={2}>
                    <DatePicker
                      format="DD/MM/YYYY"
                        selectedDate={form.contractstartdate}
                        setSelectedDate={(e) => handleChange('contractstartdate', e)}
                        label="Term Start Date"
                        className={classes.textField}
                        InputProps={{
                            classes: {
                                input: classes.input
                            }
                        }}
                    />
                </Box>

                <Box mt={3}>
                    <DatePicker
                      format="DD/MM/YYYY"
                        selectedDate={form.contractenddate}
                        minDate={form.contractstartdate}
                        setSelectedDate={(e) => handleChange('contractenddate', e)}
                        label="Term End Date"
                        minErrorText="Date cannot be before term start date"
                        className={classes.textField}
                        InputProps={{
                            classes: {
                                input: classes.input
                            }
                        }}
                    />
                </Box>
            </Box>
        </ModalWrapper>)
    );
};

export default TenantModal;
