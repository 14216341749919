import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { AdvancedMarker, InfoWindow, Marker, Pin, useAdvancedMarkerRef, useMap } from '@vis.gl/react-google-maps';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import MarkerWithInfo from './MarkerWithInfo';
import * as actions from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';

const Clusters = ({coordinates, assets}) => {
  const [markers, setMarkers] = useState([]);
  const rentData = useSelector((state) => state.tenants.totalRentByAsset);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!coordinates || coordinates.length === 0) {
      return;
    }

    dispatch(actions.getTotalRentByAssetAndYear(coordinates.map((cor) => cor.assetid), new Date().getFullYear()))
  }, [coordinates]);

  const map = useMap();
  const clusterer = useMemo(() => {
    if (!map) return null;

    return new MarkerClusterer({map});
  }, [map]);

  const setMarkerRef = useCallback((marker, key) => {
    setMarkers(markers => {
      if ((marker && markers[key]) || (!marker && !markers[key]))
        return markers;

      if (marker) {
        return {...markers, [key]: marker};
      } else {
        const {[key]: _, ...newMarkers} = markers;

        return newMarkers;
      }
    });
  }, []);

  useEffect(() => {
    if (!clusterer) return;

    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
  }, [clusterer, markers]);

  if (!rentData.loaded) {
    return null;
  }

  return (
    <>
      {coordinates.map(cor =>{
        const rent = rentData.data.find((r) => r.assetid === cor.assetid)?.rent || 0;
        const asset =  {
          ...cor,
          ...assets.find((asset) => asset.id === cor.assetid),
          rent
        };

        return (
        <MarkerWithInfo
          key={asset.assetid}
          place={asset}
          setMarkerRef={setMarkerRef}
        />
      )})}
    </>
  );
}

export default Clusters;

