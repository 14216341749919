import React, {useRef, useState} from 'react';

import Avatar from '@mui/material/Avatar';
import {green} from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import clsx from 'clsx';
import {closeModal} from 'components/Modal/modalReducer';
import ModalWrapper from 'components/Modal/ModalWrapper';
import {antivirusCorrectedStorage, ref, uploadBytesResumable} from 'config/firebase';
import {convertBytesToKB, getEnv} from 'helpers/utils';
import isNull from 'lodash/isNull';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import * as actions from 'store/actions';
import {runAntivirusScanCorrected} from 'store/actions/actualAction';
import {Grid2, TextField} from "@mui/material";

const useStyles = makeStyles(() => ({
    root: {
        border: '1px dashed #ddd',
        marginBottom: '20px',
        cursor: 'pointer',
        textAlign: 'center',
        display: 'flex',
        '&:hover p,&:hover svg': {
            opacity: 1
        },
        '& p, svg': {
            opacity: 0.4
        }
    },
    hidden: {
        display: 'none'
    },
    onDragOver: {
        border: '1px dashed #136919'
    },
    rounded: {
        color: '#fff',
        backgroundColor: green[500]
    },
    padding: {
        padding: '5px'
    },
    marginTop: {
        marginTop: '10px'
    },
    link: {
        color: '#666'
    }
}));

const HOVER_LABEL = 'Click or drag to upload file';
const DROP_LABEL = 'Drop file here';

const UploadDocumentModal = () => {
    const [file, setFile] = useState(null);
    const [isDragOver, setIsDragOver] = useState(false);
    const [loading, setLoading] = useState(false);

    const [labelText, setLabelText] = useState(HOVER_LABEL);
    const fileInputField = useRef(null);
    const classes = useStyles();
    const dispatch = useDispatch();
    const {data: assetDetails} = useSelector((state) => state.assets.assetDetails);
    const [documentType, setDocumentType] = useState();

    const stopDefaults = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };
    const dragEvents = {
        onDragEnter: (e) => {
            stopDefaults(e);
            setIsDragOver(true);
            setLabelText(DROP_LABEL);
        },
        onDragLeave: (e) => {
            stopDefaults(e);
            setIsDragOver(false);
            setLabelText(HOVER_LABEL);
        },
        onDragOver: stopDefaults,
        onDrop: (e) => {
            stopDefaults(e);
            setIsDragOver(false);
            setLabelText(HOVER_LABEL);
            setFile(e.dataTransfer.files[0]);
        }
    };

    const handleFileSelect = (e) => {
        setFile(e.target.files[0]);
    };

    const handleFileUploadBtnClick = () => {
        fileInputField.current.click();
    };

    const removeFile = () => {
        setFile(null);
    };

    const handleFileUpload = async () => {
        setLoading(true);

        const pathFrom = `document-${assetDetails.id}-${file.name}`;
        const storageRef = ref(antivirusCorrectedStorage, pathFrom);
        const generatedName = `${assetDetails.id}-${new Date().getTime()}-${file.name}`;
        await uploadBytesResumable(storageRef, file)

        const {
            infected,
            error
        } = await runAntivirusScanCorrected(pathFrom, generatedName, `ad-documents-${getEnv()}`);

        if (error) {
            toast.error(error);
            return;
        }

        if (infected) {
            toast.error('File infected!');
            return;
        }

        dispatch(actions.createDocument(assetDetails.id, null, documentType, file.type, generatedName))
            .then(() => {
                toast.success('Document uploaded succesfully!');
                dispatch(actions.getDocumentData(assetDetails.id));
            })
            .catch((err) => {
                toast.error(err);
            })
            .finally(() => {
                setLoading(false);
                dispatch(closeModal());
            });
    }

    return (
        (<ModalWrapper
            size="xl"
            header="Upload Document"
            handleSave={handleFileUpload}
            disabled={isNull(file)}
            loading={loading}
            label="Upload"
        >
            <TextField
                fullWidth
                label="Document Type"
                variant="outlined"
                className={classes.textField}
                sx={{marginBottom: 2}}
                value={documentType}
                disabled={loading}
                onChange={(e) => setDocumentType(e.target.value)}
                id="company"
                slotProps={{
                    input: {
                        classes: {
                            input: classes.input
                        }
                    }
                }}
            />
            <input
                className={classes.hidden}
                type="file"
                ref={fileInputField}
                onChange={handleFileSelect}
                accept="image/*, .pdf, .doc, .docx, .txt, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                data-testid="file-input"
            />
            <Grid2
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={clsx(classes.root, isDragOver && classes.onDragOver)}
                {...dragEvents}
                data-testid="dropzone"
                onClick={handleFileUploadBtnClick}
            >
                <IconButton
                    sx={{pointerEvents: 'none'}}
                    color="primary"
                    aria-label="upload file"
                    component="span"
                    size="large">
                    <CloudUploadIcon fontSize="large"/>
                </IconButton>
                <Typography sx={{pointerEvents: 'none'}} component="span" variant="subtitle1" paragraph>
                    {labelText}
                </Typography>
            </Grid2>
            {file && (
                <>
                    <Paper className={classes.padding} data-testid="dropped-item">
                        <Grid2 container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid2 className={classes.padding}>
                                <Avatar variant="rounded" className={classes.rounded}>
                                    <AssignmentIcon fontSize="large"/>
                                </Avatar>
                            </Grid2>
                            <Grid2 className={classes.padding}>
                                {file.name}
                            </Grid2>
                            <Grid2 className={classes.padding}>
                                {convertBytesToKB(file.size)}
                                kb
                            </Grid2>
                            <Grid2>
                                <IconButton onClick={removeFile} size="large">
                                    <DeleteIcon color="secondary"/>
                                </IconButton>
                            </Grid2>
                        </Grid2>
                    </Paper>
                </>
            )}
        </ModalWrapper>)
    );
};
export default UploadDocumentModal;
