import React, {useEffect, useState} from 'react';

import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { openModal } from 'components/Modal/modalReducer';
import useRouter from 'hooks/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions';

import AGGridTable from "../../../views/AGGrid/AGGrid";
import {formatNumberToCurrency} from "../../../helpers/utils";
import {toast} from "react-toastify";

const useStyles = makeStyles((theme) => ({
  ctaButton: {
    borderRadius: 60,
    height: '100%',
    width: theme.spacing(17),
    color: theme.palette.primary.main,
    background: theme.palette.common.white,
    fontWeight: 'bold',
    boxShadow: 'none',
    border: '1px solid #5381EF',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white
    },
    '&:hover .MuiIcon-root': {
      color: 'white !important'
    },
    '&:hover .MuiIconButton-root:hover': {
      backgroundColor: 'transparent !important'
    }
  }
}));
const Valuations = () => {
  const router = useRouter();
  const query = router.query
  const dispatch = useDispatch();
  const classes = useStyles();
  const { valuations, loaded, error } = useSelector((state) => state.valuations);
  const { data: asset } = useSelector((state) => state.assets.assetDetails);

  useEffect(() => {
    if (!query.id || (query.id === asset.id && loaded)) {
      return;
    }
    dispatch(actions.getValuations(query.id));
  }, []);

  const handleNew = () => {
    dispatch(
      openModal({
        modalType: 'ValuationModal',
        modalProps: {
          valuations
        }
      })
    );
  };

  const [colDefs, setColDefs] = useState([
    {
      headerName: '',
      field: 'rowSelection',
      checkboxSelection: true,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true,
      maxWidth: 60
    },
    { headerName: 'Valuation', field: 'valuation' },
    {
      headerName: 'Date',
      field: 'date',
      cellDataType: 'date',
      filter: 'agDateColumnFilter'
    },
    {
      headerName: 'Fair Market Value',
      field: 'marketvalue',
      cellRenderer: currencyFormatter,
    },
    {
      headerName: 'Liquidation Value',
      field: 'liquidationvalue',
      cellRenderer: currencyFormatter,
    },
    {
      headerName: 'Valuer',
      field: 'valuer'
    },
    {
      cellRenderer: params => {
        return (
            <Button
                onClick={() => router.push(`/Asset/${router.query.id}/Valuations/${params.data.id}`)}
                startIcon={<Icon style={{color: 'grey'}}>edit</Icon>}
            >

            </Button>
        );
      }
    }
  ]);

  function currencyFormatter(params) {
    return params.value === 0 ? '-' : formatNumberToCurrency(params.value);
  }

  if (error) {
    return (
      <Typography variant="h6" color="error" style={{ marginTop: 50, marginLeft: 50 }}>
        {error}
      </Typography>
    );
  }

  return (
      <AGGridTable
          buttons={
            [<Button
                className={classes.ctaButton}
                style={{ marginRight: '10px' }}
                startIcon={<Icon style={{ color: '#5381EF' }}>add</Icon>}
                onClick={handleNew}
            >
              VALUATION
            </Button>]
          }
          data={valuations}
          colDefs={colDefs}
          deleteHandler={(selectedRows, api) => {
            api.applyTransaction({remove: selectedRows});
            for (let i = 0; i < selectedRows.length; i++) {
              dispatch(actions.deleteValuation(selectedRows[i].id))
                  .then(() => {
                  })
                  .catch((err) => toast.error(err));
            }
          }}
          classes={classes}
      />
  );
};

export default Valuations;
