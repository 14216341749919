import React from 'react';

import { Box, TextField, InputAdornment, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DatePicker from 'components/DatePicker';
import { formatForBackEnd } from 'helpers/utils';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: 400,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main
      }
    }
  },
  input: {
    fontFamily: theme.fonts.openSans,
    color: '#5C5C5C'
  },
  box: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  remove: {
    fontFamily: theme.fonts.base,
    fontSize: 14,
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }
}));

const ScheduleItem = ({ form, setForm, index, type, item }) => {
  const [value, setValue] = React.useState(item.paymentAmount);
  const [date, setDate] = React.useState(new Date(item.paymentDate));

  const classes = useStyles();
  const formCopy = [...form.schedule.payments];

  const handleChange = (name, val) => {
    if (name === 'paymentDate') {
      setDate(val);
      formCopy[index] = { ...formCopy[index], [name]: formatForBackEnd(val) };
    } else {
      setValue(val);

      formCopy[index] = { ...formCopy[index], [name]: +val };
    }

    setForm((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        schedule: {
          payments: formCopy
        }
      }
    }));
  };

  const handleRemove = () => {
    formCopy.splice(index, 1);
    setForm((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        schedule: {
          payments: formCopy
        }
      }
    }));
  };

  return (
    (<Box>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={classes.box}
      >
        <TextField
          fullWidth
          label="Payment Amount"
          variant="outlined"
          className={classes.textField}
          type="number"
          value={value}
          onChange={(e) => handleChange('paymentAmount', e.target.value)}
          slotProps={{
            input: {
              startAdornment: <InputAdornment position="start">€</InputAdornment>,
              classes: {
                root: classes.input
              }
            }
          }}
        />

        <DatePicker
          format="DD/MM/YYYY"
          selectedDate={date}
          setSelectedDate={(e) => handleChange('paymentDate', e)}
          label="Scheduled Date"
        />
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Typography className={classes.remove} onClick={handleRemove}>
          REMOVE
        </Typography>
      </Box>
    </Box>)
  );
};

export default ScheduleItem;

ScheduleItem.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  setForm: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  item: PropTypes.objectOf(PropTypes.any).isRequired
};
