/* eslint-disable no-new */
import React from 'react';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';

const CustomAutocomplete = ({
                                items,
                                updateUrlParams,
                                urlParams,
                                label,
                                placeholder,
                                disabled
                            }) => (
    <Autocomplete
        disablePortal
        disabled={disabled}
        multiple
        options={items}
        id={`${label}-filter`}
        openOnFocus
        getOptionLabel={(option) => option.name}
        value={items.filter((item) => urlParams.includes(item.id))}
        renderTags={(values, getTagProps) => {
            return values.map((value, index) => {
                const {key, ...tagProps} = getTagProps({index});

                return (<Chip
                    key={value.id}
                    label={value.name}
                    {...tagProps}
                    style={{backgroundColor: '#e0e0e0'}}
                />)
            });
        }}
        style={{width: '100%'}}
        onChange={(event, values) => updateUrlParams(values.map((item) => item.id))}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          );
        }}
        renderInput={(params) => (
            <TextField
                {...params}
                variant="outlined"
                label={label}
                placeholder={urlParams.length > 0 ? null : placeholder}
            />
        )}
    />
);
export default CustomAutocomplete;

CustomAutocomplete.defaultProps = {
    items: [],
    urlParams: [],
    label: '',
    placeholder: '',
    disabled: false
};

CustomAutocomplete.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    urlParams: PropTypes.arrayOf(PropTypes.string),
    updateUrlParams: PropTypes.func.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool
};
