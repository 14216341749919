/* eslint-disable function-paren-newline */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { lazy, Suspense, useEffect } from 'react';

import Box from '@mui/material/Box';
import Spinner from 'components/Spinner';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import SideMenu from './SideMenu';
import AssetChartDetails from './AssetChartDetails';
import RootContainer from '../../../styles/rootContainer';
import RootPaper from '../../../styles/rootPaper';
import Header from './Header';
import * as actions from '../../../store/actions';
import useRouter from '../../../hooks/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { openModal } from '../../../components/Modal/modalReducer';
import EditIcon from '@mui/icons-material/Edit';

const Actuals = lazy(() => import('./Actuals'));
const Turnover = lazy(() => import('./Turnover'));
const Documents = lazy(() => import('./Documents'));
const Tenants = lazy(() => import('./Tenants'));
const Details = lazy(() => import('./Details'));
const CashManager = lazy(() => import('Workflows/Shared/CashManager/AssetCashManager'));
const OpexManager = lazy(() => import('Workflows/Property/OpexManager'));
const FacilityManager = lazy(() => import('Workflows/Property/FacilityManager'));
const CostManager = lazy(() => import('Workflows/Asset/AssetCostManager'));
const LegalTracker = lazy(() => import('Workflows/Asset/LegalTracker'));
const Valuations = lazy(() => import('Workflows/Asset/Valuations'));
const AdvancedReports = lazy(() => import('./AdvancedReports'));
const AssetCostManagerFlow = lazy(() =>
  import('Workflows/Asset/AssetCostManager/AssetCostManagerFlow')
);
const AssetLegalTrackerDetails = lazy(() =>
  import('Workflows/Asset/LegalTracker/LegalTrackerDetails')
);
const ValuationsDetails = lazy(() => import('Workflows/Asset/Valuations/ValuationsDetails'));
const FacilityManagerDetails = lazy(() =>
  import('Workflows/Property/FacilityManager/FacilityDetails')
);
const OpexManagerFlow = lazy(() => import('Workflows/Property/OpexManager/OpexManagerFlow'));

const routes = [
  { name: 'Details', path: '/Asset/:id/Details', component: Details },
  { name: 'Tenants', path: '/Asset/:id/Tenants', component: Tenants },
  { name: 'Opex Manager', path: '/Asset/:id/OpexManager', component: OpexManager },
  { name: 'Facility manager', path: '/Asset/:id/FacilityManager', component: FacilityManager },
  { name: 'Turnover', path: '/Asset/:id/Turnover', component: Turnover },
  { name: 'Actuals', path: '/Asset/:id/Actuals', component: Actuals },
  { name: 'Valuations', path: '/Asset/:id/Valuations', component: Valuations },
  { name: 'Cost manager', path: '/Asset/:id/CostManager', component: CostManager },
  { name: 'Cash manager', path: '/Asset/:id/CashManager', component: CashManager },
  { name: 'Legal tracker', path: '/Asset/:id/LegalTracker', component: LegalTracker },
  { name: 'Reports', path: '/Asset/:id/Reports', component: AssetChartDetails },
  { name: 'Advanced reporting', path: '/Asset/:id/AdvancedReporting', component: AdvancedReports },
  { name: 'Documents', path: '/Asset/:id/Documents', component: Documents },
  { name: 'Opex manager', path: '/Asset/:id/OpexManager/:index', component: OpexManagerFlow },
  { name: 'Facility manager', path: '/Asset/:id/FacilityManager/:index', component: FacilityManagerDetails },
  { name: 'Legal tracker', path: '/Asset/:id/LegalTracker/:index', component: AssetLegalTrackerDetails },
  { name: 'Cost manager', path: '/Asset/:id/CostManager/:index', component: AssetCostManagerFlow },
  { name: 'Valuations', path: '/Asset/:id/Valuations/:index', component: ValuationsDetails }
];

const AssetDetails = () => {
  const isDetailsPage = Boolean(useRouteMatch('/Asset/:id/Details'));
  const {data: asset, loaded} = useSelector((state) => state.assets.assetDetails);
  const router = useRouter();
  const query = router.query;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!query.id || loaded) {
      return;
    }

    dispatch(actions.getAssetDetails(query.id));
  }, [query.id]);

  if (!loaded) {
    return <Spinner />;
  }

  return (
    <RootContainer>
      <SideMenu />
      <Box width="100%" sx={{
        padding: '20px',
        maxHeight: 'calc(100vh - 73px)',
        overflowY: 'scroll'
      }}>
        <Box>
          <Suspense fallback={<Spinner mini />}>
            <Switch>
              {routes.map((route) => (
                <Route exact path={route.path} key={route.path}>
                  {isDetailsPage && <Header/>}
                  {isDetailsPage && <br/>}
                  <RootPaper>
                    {!isDetailsPage && <Box sx={{padding: '10px 20px'}}>
                      <Typography variant="h4" sx={{
                        fontWeight: 400
                      }}>
                        {route.name}
                      </Typography>
                      <br/>
                      <Typography variant="h5" sx={{
                        fontWeight: 400,
                        color: '#5F5F5F'
                      }}>
                        {asset.name}
                      </Typography>
                    </Box>}
                    <route.component />
                  </RootPaper>
                </Route>
              ))}
            </Switch>
          </Suspense>
        </Box>
      </Box>
    </RootContainer>
  );
};

export default AssetDetails;
