import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import UserHasAccess from 'hooks/UserHasAccess';
import { useSelector } from 'react-redux';

import Navbar from '../components/Navbar';
import Routes from '../routes';
import Intercom from '@intercom/messenger-js-sdk';
import ADA from '../views/ADA/ADA';

const Layout = () => {
  const userPermissionsLoaded = useSelector((state) => state.user.userRolesLoaded);
  const user = useSelector((state) => state.user.user);
  const userPermissions = useSelector((state) => state.user.userPermissions.permissionsList);
  const ReadPortfoliosPermissions = UserHasAccess('portfolio', 'view', userPermissions);

  if (process.env.NODE_ENV === 'production') {
    Intercom({
      app_id: 'f91q50u6',
      user_id: user.id,
      name: user.displayname,
      email: user.email,
      created_at: user.createdAt,
      user_hash: user.intercomhmac
    });
  }

  return (
    <>
      <Navbar />
      {window.location.hostname.includes('pre-prod') && <ADA />}
      <Box height="100%">
        {userPermissionsLoaded && <Routes userPermissions={userPermissions}/>}
        {!ReadPortfoliosPermissions && userPermissionsLoaded && (
          <Box ml={3} mt={3} display="flex" justifyContent="center" alignItems="center">
            <Typography color="error">
              Your account has no permissions to interact with the app, if you are under the right
              organisation account please contact an admin to upgrade your guest account
            </Typography>
            <Box pl={3} pr={3}>
              <Button
                variant="contained"
                color="primary"
                rel="noopener noreferrer"
                href="mailto:thanos.tsavlis@resolute-datascience.com"
                endIcon={<Icon>send</Icon>}
              >
                Request Access
              </Button>
            </Box>
          </Box>
        )}
      </Box>

    </>
  );
};

export default React.memo(Layout);
