import { DocumentServiceClient } from 'api/document_grpc_web_pb';
import { getEnviroment } from 'config/config';
import {enableDevTools} from "./webtool";

const env = localStorage.getItem('env');

export const DocumentService = new DocumentServiceClient(getEnviroment(env));

enableDevTools([
    DocumentService
]);