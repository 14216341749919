import React, { lazy, Suspense, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Spinner from 'components/Spinner';
import AddCostManager from 'forms/AddCostManager';
import useRouter from 'hooks/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';

const CostTable = lazy(() => import('../../Shared/CostManager/CostTable'));

const useStyles = makeStyles((theme) => ({
  ctaButton: {
    borderRadius: 60,
    height: '100%',
    width: theme.spacing(13),
    color: theme.palette.primary.main,
    background: theme.palette.common.white,
    fontWeight: 'bold',
    boxShadow: 'none',
    border: '1px solid #5381EF',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white
    },
    '&:hover .MuiIcon-root': {
      color: 'white !important'
    },
    '&:hover .MuiIconButton-root:hover': {
      backgroundColor: 'transparent !important'
    }
  }
}));

const AssetCostManager = () => {
  const { query } = useRouter();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [formName, setFormName] = useState('');
  const { costManager, loading, loaded, error } = useSelector((state) => state.assetCostManager);

  useEffect(() => {
    dispatch(actions.getAssetCostManager(query.id));
  }, []);

  const handleNew = () => {
    setFormName('');
    setOpen(true);
  };

  const handleClose = () => {
    setFormName('');
    setOpen(false);
  };

  const handleSave = (form) => {
    dispatch(actions.createAssetCostManager(query.id, form)).then(() => {
      dispatch(actions.getAssetCostManager(query.id))
        .then(() => {
          toast.success('Capex Manager Added');
          handleClose();
        })
        .catch((err) => {
          toast.error(err);
        });
    });
  };

  function handleDeleteCostManager(id) {
    dispatch(actions.deleteAssetCostManager(id)).then(() => {
      dispatch(actions.getAssetCostManager(query.id))
        .then(() => {
          toast.success('Non-Opex Manager Deleted');
          handleClose();
        })
        .catch((err) => {
          toast.error(err);
        });
    });
  }

  if (!loaded || loading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Box p={3} m={3}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Suspense fallback={<Spinner />}>
      <CostTable
        data={costManager}
        loading={loading}
        link={`/Asset/${query.id}/CostManager`}
        handleDelete={(ticketId) => handleDeleteCostManager(ticketId)}
        buttons={[
          <Button
            className={classes.ctaButton}
            style={{ marginRight: '10px' }}
            startIcon={<Icon style={{ color: '#5381EF' }}>add</Icon>}
            onClick={handleNew}
          >
            CAPEX
          </Button>,
          <AddCostManager
            open={open}
            handleClose={handleClose}
            costManager={formName}
            createCostManager={handleSave}
            title="Add Capex Manager"
            uniqueCheck={(value) => costManager.find((element) => element.name === value)}
          />
        ]}
      />
    </Suspense>
  );
};

export default AssetCostManager;
