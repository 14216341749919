// source: external/aggregation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.external.AssetAggregation', null, global);
goog.exportSymbol('proto.external.AssetAggregationResponse', null, global);
goog.exportSymbol('proto.external.AssetKpiResponse', null, global);
goog.exportSymbol('proto.external.AssetRequest', null, global);
goog.exportSymbol('proto.external.GetAssetAggregationRequest', null, global);
goog.exportSymbol('proto.external.GetBreadCrumbsRequest', null, global);
goog.exportSymbol('proto.external.GetBreadCrumbsResponse', null, global);
goog.exportSymbol('proto.external.GetTenantByPortfolioIDsRequest', null, global);
goog.exportSymbol('proto.external.GetTenantByPortfolioIDsResponse', null, global);
goog.exportSymbol('proto.external.GetTenantHeaderRequest', null, global);
goog.exportSymbol('proto.external.GetTenantHeaderResponse', null, global);
goog.exportSymbol('proto.external.GetTotalsByTypesRequest', null, global);
goog.exportSymbol('proto.external.GetTotalsByTypesResponse', null, global);
goog.exportSymbol('proto.external.PortfolioAggregation', null, global);
goog.exportSymbol('proto.external.PortfoliosAggregationResponse', null, global);
goog.exportSymbol('proto.external.TenantResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.AssetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.AssetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.AssetRequest.displayName = 'proto.external.AssetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.AssetKpiResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.AssetKpiResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.AssetKpiResponse.displayName = 'proto.external.AssetKpiResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantByPortfolioIDsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetTenantByPortfolioIDsRequest.repeatedFields_, null);
};
goog.inherits(proto.external.GetTenantByPortfolioIDsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantByPortfolioIDsRequest.displayName = 'proto.external.GetTenantByPortfolioIDsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantByPortfolioIDsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetTenantByPortfolioIDsResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetTenantByPortfolioIDsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantByPortfolioIDsResponse.displayName = 'proto.external.GetTenantByPortfolioIDsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.TenantResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.TenantResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.TenantResponse.displayName = 'proto.external.TenantResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantHeaderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetTenantHeaderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantHeaderRequest.displayName = 'proto.external.GetTenantHeaderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantHeaderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetTenantHeaderResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetTenantHeaderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantHeaderResponse.displayName = 'proto.external.GetTenantHeaderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetBreadCrumbsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetBreadCrumbsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetBreadCrumbsRequest.displayName = 'proto.external.GetBreadCrumbsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetBreadCrumbsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetBreadCrumbsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetBreadCrumbsResponse.displayName = 'proto.external.GetBreadCrumbsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetAssetAggregationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetAssetAggregationRequest.repeatedFields_, null);
};
goog.inherits(proto.external.GetAssetAggregationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetAssetAggregationRequest.displayName = 'proto.external.GetAssetAggregationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.AssetAggregationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.AssetAggregationResponse.repeatedFields_, null);
};
goog.inherits(proto.external.AssetAggregationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.AssetAggregationResponse.displayName = 'proto.external.AssetAggregationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.AssetAggregation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.AssetAggregation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.AssetAggregation.displayName = 'proto.external.AssetAggregation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.PortfoliosAggregationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.PortfoliosAggregationResponse.repeatedFields_, null);
};
goog.inherits(proto.external.PortfoliosAggregationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.PortfoliosAggregationResponse.displayName = 'proto.external.PortfoliosAggregationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.PortfolioAggregation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.PortfolioAggregation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.PortfolioAggregation.displayName = 'proto.external.PortfolioAggregation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTotalsByTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetTotalsByTypesRequest.repeatedFields_, null);
};
goog.inherits(proto.external.GetTotalsByTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTotalsByTypesRequest.displayName = 'proto.external.GetTotalsByTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTotalsByTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetTotalsByTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTotalsByTypesResponse.displayName = 'proto.external.GetTotalsByTypesResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.AssetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.AssetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.AssetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AssetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.AssetRequest}
 */
proto.external.AssetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.AssetRequest;
  return proto.external.AssetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.AssetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.AssetRequest}
 */
proto.external.AssetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.AssetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.AssetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.AssetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AssetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.AssetRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AssetRequest} returns this
 */
proto.external.AssetRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.AssetKpiResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.AssetKpiResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.AssetKpiResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AssetKpiResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantcount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    unitcount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    occupancyrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    leasedarea: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    annualrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    annualnoi: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    valuation: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    footfall: jspb.Message.getFieldWithDefault(msg, 8, 0),
    totalgla: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    valuationdate: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.AssetKpiResponse}
 */
proto.external.AssetKpiResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.AssetKpiResponse;
  return proto.external.AssetKpiResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.AssetKpiResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.AssetKpiResponse}
 */
proto.external.AssetKpiResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTenantcount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUnitcount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOccupancyrate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLeasedarea(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAnnualrent(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAnnualnoi(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValuation(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFootfall(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalgla(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValuationdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.AssetKpiResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.AssetKpiResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.AssetKpiResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AssetKpiResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantcount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUnitcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOccupancyrate();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getLeasedarea();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getAnnualrent();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getAnnualnoi();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getValuation();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getFootfall();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getTotalgla();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getValuationdate();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
};


/**
 * optional int32 tenantCount = 1;
 * @return {number}
 */
proto.external.AssetKpiResponse.prototype.getTenantcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.AssetKpiResponse} returns this
 */
proto.external.AssetKpiResponse.prototype.setTenantcount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 unitCount = 2;
 * @return {number}
 */
proto.external.AssetKpiResponse.prototype.getUnitcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.AssetKpiResponse} returns this
 */
proto.external.AssetKpiResponse.prototype.setUnitcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double occupancyRate = 3;
 * @return {number}
 */
proto.external.AssetKpiResponse.prototype.getOccupancyrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.AssetKpiResponse} returns this
 */
proto.external.AssetKpiResponse.prototype.setOccupancyrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double leasedArea = 4;
 * @return {number}
 */
proto.external.AssetKpiResponse.prototype.getLeasedarea = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.AssetKpiResponse} returns this
 */
proto.external.AssetKpiResponse.prototype.setLeasedarea = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double annualRent = 5;
 * @return {number}
 */
proto.external.AssetKpiResponse.prototype.getAnnualrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.AssetKpiResponse} returns this
 */
proto.external.AssetKpiResponse.prototype.setAnnualrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double annualNOI = 6;
 * @return {number}
 */
proto.external.AssetKpiResponse.prototype.getAnnualnoi = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.AssetKpiResponse} returns this
 */
proto.external.AssetKpiResponse.prototype.setAnnualnoi = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double valuation = 7;
 * @return {number}
 */
proto.external.AssetKpiResponse.prototype.getValuation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.AssetKpiResponse} returns this
 */
proto.external.AssetKpiResponse.prototype.setValuation = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional int32 footfall = 8;
 * @return {number}
 */
proto.external.AssetKpiResponse.prototype.getFootfall = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.AssetKpiResponse} returns this
 */
proto.external.AssetKpiResponse.prototype.setFootfall = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional double totalGLA = 9;
 * @return {number}
 */
proto.external.AssetKpiResponse.prototype.getTotalgla = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.AssetKpiResponse} returns this
 */
proto.external.AssetKpiResponse.prototype.setTotalgla = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional int64 valuationDate = 10;
 * @return {number}
 */
proto.external.AssetKpiResponse.prototype.getValuationdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.AssetKpiResponse} returns this
 */
proto.external.AssetKpiResponse.prototype.setValuationdate = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetTenantByPortfolioIDsRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetTenantByPortfolioIDsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetTenantByPortfolioIDsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetTenantByPortfolioIDsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantByPortfolioIDsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    portfoliosList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    assetsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantByPortfolioIDsRequest}
 */
proto.external.GetTenantByPortfolioIDsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantByPortfolioIDsRequest;
  return proto.external.GetTenantByPortfolioIDsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantByPortfolioIDsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantByPortfolioIDsRequest}
 */
proto.external.GetTenantByPortfolioIDsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addPortfolios(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addAssets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantByPortfolioIDsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantByPortfolioIDsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantByPortfolioIDsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantByPortfolioIDsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPortfoliosList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string portfolios = 1;
 * @return {!Array<string>}
 */
proto.external.GetTenantByPortfolioIDsRequest.prototype.getPortfoliosList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.external.GetTenantByPortfolioIDsRequest} returns this
 */
proto.external.GetTenantByPortfolioIDsRequest.prototype.setPortfoliosList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.external.GetTenantByPortfolioIDsRequest} returns this
 */
proto.external.GetTenantByPortfolioIDsRequest.prototype.addPortfolios = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetTenantByPortfolioIDsRequest} returns this
 */
proto.external.GetTenantByPortfolioIDsRequest.prototype.clearPortfoliosList = function() {
  return this.setPortfoliosList([]);
};


/**
 * repeated string assets = 2;
 * @return {!Array<string>}
 */
proto.external.GetTenantByPortfolioIDsRequest.prototype.getAssetsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.external.GetTenantByPortfolioIDsRequest} returns this
 */
proto.external.GetTenantByPortfolioIDsRequest.prototype.setAssetsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.external.GetTenantByPortfolioIDsRequest} returns this
 */
proto.external.GetTenantByPortfolioIDsRequest.prototype.addAssets = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetTenantByPortfolioIDsRequest} returns this
 */
proto.external.GetTenantByPortfolioIDsRequest.prototype.clearAssetsList = function() {
  return this.setAssetsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetTenantByPortfolioIDsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetTenantByPortfolioIDsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetTenantByPortfolioIDsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetTenantByPortfolioIDsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantByPortfolioIDsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantsList: jspb.Message.toObjectList(msg.getTenantsList(),
    proto.external.TenantResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantByPortfolioIDsResponse}
 */
proto.external.GetTenantByPortfolioIDsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantByPortfolioIDsResponse;
  return proto.external.GetTenantByPortfolioIDsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantByPortfolioIDsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantByPortfolioIDsResponse}
 */
proto.external.GetTenantByPortfolioIDsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.external.TenantResponse;
      reader.readMessage(value,proto.external.TenantResponse.deserializeBinaryFromReader);
      msg.addTenants(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantByPortfolioIDsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantByPortfolioIDsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantByPortfolioIDsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantByPortfolioIDsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.TenantResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TenantResponse tenants = 1;
 * @return {!Array<!proto.external.TenantResponse>}
 */
proto.external.GetTenantByPortfolioIDsResponse.prototype.getTenantsList = function() {
  return /** @type{!Array<!proto.external.TenantResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.TenantResponse, 1));
};


/**
 * @param {!Array<!proto.external.TenantResponse>} value
 * @return {!proto.external.GetTenantByPortfolioIDsResponse} returns this
*/
proto.external.GetTenantByPortfolioIDsResponse.prototype.setTenantsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.TenantResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.TenantResponse}
 */
proto.external.GetTenantByPortfolioIDsResponse.prototype.addTenants = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.TenantResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetTenantByPortfolioIDsResponse} returns this
 */
proto.external.GetTenantByPortfolioIDsResponse.prototype.clearTenantsList = function() {
  return this.setTenantsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.TenantResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.TenantResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.TenantResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TenantResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tradingname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    industry: jspb.Message.getFieldWithDefault(msg, 3, ""),
    country: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, ""),
    street: jspb.Message.getFieldWithDefault(msg, 6, ""),
    postcode: jspb.Message.getFieldWithDefault(msg, 7, ""),
    assetid: jspb.Message.getFieldWithDefault(msg, 8, ""),
    id: jspb.Message.getFieldWithDefault(msg, 9, ""),
    inplace: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    contractstartdate: jspb.Message.getFieldWithDefault(msg, 11, ""),
    contractenddate: jspb.Message.getFieldWithDefault(msg, 12, ""),
    assetname: jspb.Message.getFieldWithDefault(msg, 13, ""),
    totalgla: jspb.Message.getFieldWithDefault(msg, 14, 0),
    totalrent: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.TenantResponse}
 */
proto.external.TenantResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.TenantResponse;
  return proto.external.TenantResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.TenantResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.TenantResponse}
 */
proto.external.TenantResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTradingname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndustry(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostcode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInplace(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractstartdate(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractenddate(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetname(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalgla(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalrent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.TenantResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.TenantResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.TenantResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TenantResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTradingname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIndustry();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPostcode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getInplace();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getContractstartdate();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getContractenddate();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAssetname();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getTotalgla();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getTotalrent();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
};


/**
 * optional string companyName = 1;
 * @return {string}
 */
proto.external.TenantResponse.prototype.getCompanyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantResponse} returns this
 */
proto.external.TenantResponse.prototype.setCompanyname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tradingName = 2;
 * @return {string}
 */
proto.external.TenantResponse.prototype.getTradingname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantResponse} returns this
 */
proto.external.TenantResponse.prototype.setTradingname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string industry = 3;
 * @return {string}
 */
proto.external.TenantResponse.prototype.getIndustry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantResponse} returns this
 */
proto.external.TenantResponse.prototype.setIndustry = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string country = 4;
 * @return {string}
 */
proto.external.TenantResponse.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantResponse} returns this
 */
proto.external.TenantResponse.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.external.TenantResponse.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantResponse} returns this
 */
proto.external.TenantResponse.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string street = 6;
 * @return {string}
 */
proto.external.TenantResponse.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantResponse} returns this
 */
proto.external.TenantResponse.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string postcode = 7;
 * @return {string}
 */
proto.external.TenantResponse.prototype.getPostcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantResponse} returns this
 */
proto.external.TenantResponse.prototype.setPostcode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string assetID = 8;
 * @return {string}
 */
proto.external.TenantResponse.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantResponse} returns this
 */
proto.external.TenantResponse.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string id = 9;
 * @return {string}
 */
proto.external.TenantResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantResponse} returns this
 */
proto.external.TenantResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool inPlace = 10;
 * @return {boolean}
 */
proto.external.TenantResponse.prototype.getInplace = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.external.TenantResponse} returns this
 */
proto.external.TenantResponse.prototype.setInplace = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string contractStartDate = 11;
 * @return {string}
 */
proto.external.TenantResponse.prototype.getContractstartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantResponse} returns this
 */
proto.external.TenantResponse.prototype.setContractstartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string contractEndDate = 12;
 * @return {string}
 */
proto.external.TenantResponse.prototype.getContractenddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantResponse} returns this
 */
proto.external.TenantResponse.prototype.setContractenddate = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string assetName = 13;
 * @return {string}
 */
proto.external.TenantResponse.prototype.getAssetname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantResponse} returns this
 */
proto.external.TenantResponse.prototype.setAssetname = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int32 totalGLA = 14;
 * @return {number}
 */
proto.external.TenantResponse.prototype.getTotalgla = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.TenantResponse} returns this
 */
proto.external.TenantResponse.prototype.setTotalgla = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 totalRent = 15;
 * @return {number}
 */
proto.external.TenantResponse.prototype.getTotalrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.TenantResponse} returns this
 */
proto.external.TenantResponse.prototype.setTotalrent = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetTenantHeaderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetTenantHeaderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetTenantHeaderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantHeaderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantHeaderRequest}
 */
proto.external.GetTenantHeaderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantHeaderRequest;
  return proto.external.GetTenantHeaderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantHeaderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantHeaderRequest}
 */
proto.external.GetTenantHeaderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantHeaderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantHeaderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantHeaderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantHeaderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenantID = 1;
 * @return {string}
 */
proto.external.GetTenantHeaderRequest.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantHeaderRequest} returns this
 */
proto.external.GetTenantHeaderRequest.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetTenantHeaderResponse.repeatedFields_ = [2,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetTenantHeaderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetTenantHeaderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetTenantHeaderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantHeaderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    unitnamesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    totalgla: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    floorsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    assetid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    contractstartdate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    contractenddate: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantHeaderResponse}
 */
proto.external.GetTenantHeaderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantHeaderResponse;
  return proto.external.GetTenantHeaderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantHeaderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantHeaderResponse}
 */
proto.external.GetTenantHeaderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addUnitnames(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalgla(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFloors(values[i]);
      }
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractstartdate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractenddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantHeaderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantHeaderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantHeaderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantHeaderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUnitnamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getTotalgla();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getFloorsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getContractstartdate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getContractenddate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string companyName = 1;
 * @return {string}
 */
proto.external.GetTenantHeaderResponse.prototype.getCompanyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantHeaderResponse} returns this
 */
proto.external.GetTenantHeaderResponse.prototype.setCompanyname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string unitNames = 2;
 * @return {!Array<string>}
 */
proto.external.GetTenantHeaderResponse.prototype.getUnitnamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.external.GetTenantHeaderResponse} returns this
 */
proto.external.GetTenantHeaderResponse.prototype.setUnitnamesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.external.GetTenantHeaderResponse} returns this
 */
proto.external.GetTenantHeaderResponse.prototype.addUnitnames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetTenantHeaderResponse} returns this
 */
proto.external.GetTenantHeaderResponse.prototype.clearUnitnamesList = function() {
  return this.setUnitnamesList([]);
};


/**
 * optional float totalGLA = 3;
 * @return {number}
 */
proto.external.GetTenantHeaderResponse.prototype.getTotalgla = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.GetTenantHeaderResponse} returns this
 */
proto.external.GetTenantHeaderResponse.prototype.setTotalgla = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated int32 floors = 4;
 * @return {!Array<number>}
 */
proto.external.GetTenantHeaderResponse.prototype.getFloorsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.external.GetTenantHeaderResponse} returns this
 */
proto.external.GetTenantHeaderResponse.prototype.setFloorsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.external.GetTenantHeaderResponse} returns this
 */
proto.external.GetTenantHeaderResponse.prototype.addFloors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetTenantHeaderResponse} returns this
 */
proto.external.GetTenantHeaderResponse.prototype.clearFloorsList = function() {
  return this.setFloorsList([]);
};


/**
 * optional string assetID = 5;
 * @return {string}
 */
proto.external.GetTenantHeaderResponse.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantHeaderResponse} returns this
 */
proto.external.GetTenantHeaderResponse.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string contractStartDate = 6;
 * @return {string}
 */
proto.external.GetTenantHeaderResponse.prototype.getContractstartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantHeaderResponse} returns this
 */
proto.external.GetTenantHeaderResponse.prototype.setContractstartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string contractEndDate = 7;
 * @return {string}
 */
proto.external.GetTenantHeaderResponse.prototype.getContractenddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantHeaderResponse} returns this
 */
proto.external.GetTenantHeaderResponse.prototype.setContractenddate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetBreadCrumbsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetBreadCrumbsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetBreadCrumbsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetBreadCrumbsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetBreadCrumbsRequest}
 */
proto.external.GetBreadCrumbsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetBreadCrumbsRequest;
  return proto.external.GetBreadCrumbsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetBreadCrumbsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetBreadCrumbsRequest}
 */
proto.external.GetBreadCrumbsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetBreadCrumbsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetBreadCrumbsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetBreadCrumbsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetBreadCrumbsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenantID = 1;
 * @return {string}
 */
proto.external.GetBreadCrumbsRequest.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.GetBreadCrumbsRequest} returns this
 */
proto.external.GetBreadCrumbsRequest.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetBreadCrumbsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetBreadCrumbsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetBreadCrumbsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetBreadCrumbsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    assetid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    assetname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    portfolioid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    portfolioname: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetBreadCrumbsResponse}
 */
proto.external.GetBreadCrumbsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetBreadCrumbsResponse;
  return proto.external.GetBreadCrumbsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetBreadCrumbsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetBreadCrumbsResponse}
 */
proto.external.GetBreadCrumbsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPortfolioid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPortfolioname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetBreadCrumbsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetBreadCrumbsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetBreadCrumbsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetBreadCrumbsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAssetname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPortfolioid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPortfolioname();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string tenantID = 1;
 * @return {string}
 */
proto.external.GetBreadCrumbsResponse.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.GetBreadCrumbsResponse} returns this
 */
proto.external.GetBreadCrumbsResponse.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenantName = 2;
 * @return {string}
 */
proto.external.GetBreadCrumbsResponse.prototype.getTenantname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.GetBreadCrumbsResponse} returns this
 */
proto.external.GetBreadCrumbsResponse.prototype.setTenantname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string assetID = 3;
 * @return {string}
 */
proto.external.GetBreadCrumbsResponse.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.GetBreadCrumbsResponse} returns this
 */
proto.external.GetBreadCrumbsResponse.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string assetName = 4;
 * @return {string}
 */
proto.external.GetBreadCrumbsResponse.prototype.getAssetname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.GetBreadCrumbsResponse} returns this
 */
proto.external.GetBreadCrumbsResponse.prototype.setAssetname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string portfolioID = 5;
 * @return {string}
 */
proto.external.GetBreadCrumbsResponse.prototype.getPortfolioid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.GetBreadCrumbsResponse} returns this
 */
proto.external.GetBreadCrumbsResponse.prototype.setPortfolioid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string portfolioName = 6;
 * @return {string}
 */
proto.external.GetBreadCrumbsResponse.prototype.getPortfolioname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.GetBreadCrumbsResponse} returns this
 */
proto.external.GetBreadCrumbsResponse.prototype.setPortfolioname = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetAssetAggregationRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetAssetAggregationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetAssetAggregationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetAssetAggregationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetAssetAggregationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    portfolioidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetAssetAggregationRequest}
 */
proto.external.GetAssetAggregationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetAssetAggregationRequest;
  return proto.external.GetAssetAggregationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetAssetAggregationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetAssetAggregationRequest}
 */
proto.external.GetAssetAggregationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addPortfolioids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetAssetAggregationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetAssetAggregationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetAssetAggregationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetAssetAggregationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPortfolioidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string portfolioIDs = 1;
 * @return {!Array<string>}
 */
proto.external.GetAssetAggregationRequest.prototype.getPortfolioidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.external.GetAssetAggregationRequest} returns this
 */
proto.external.GetAssetAggregationRequest.prototype.setPortfolioidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.external.GetAssetAggregationRequest} returns this
 */
proto.external.GetAssetAggregationRequest.prototype.addPortfolioids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetAssetAggregationRequest} returns this
 */
proto.external.GetAssetAggregationRequest.prototype.clearPortfolioidsList = function() {
  return this.setPortfolioidsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.AssetAggregationResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.AssetAggregationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.AssetAggregationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.AssetAggregationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AssetAggregationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetsList: jspb.Message.toObjectList(msg.getAssetsList(),
    proto.external.AssetAggregation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.AssetAggregationResponse}
 */
proto.external.AssetAggregationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.AssetAggregationResponse;
  return proto.external.AssetAggregationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.AssetAggregationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.AssetAggregationResponse}
 */
proto.external.AssetAggregationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.external.AssetAggregation;
      reader.readMessage(value,proto.external.AssetAggregation.deserializeBinaryFromReader);
      msg.addAssets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.AssetAggregationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.AssetAggregationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.AssetAggregationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AssetAggregationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.AssetAggregation.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AssetAggregation assets = 1;
 * @return {!Array<!proto.external.AssetAggregation>}
 */
proto.external.AssetAggregationResponse.prototype.getAssetsList = function() {
  return /** @type{!Array<!proto.external.AssetAggregation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.AssetAggregation, 1));
};


/**
 * @param {!Array<!proto.external.AssetAggregation>} value
 * @return {!proto.external.AssetAggregationResponse} returns this
*/
proto.external.AssetAggregationResponse.prototype.setAssetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.AssetAggregation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.AssetAggregation}
 */
proto.external.AssetAggregationResponse.prototype.addAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.AssetAggregation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.AssetAggregationResponse} returns this
 */
proto.external.AssetAggregationResponse.prototype.clearAssetsList = function() {
  return this.setAssetsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.AssetAggregation.prototype.toObject = function(opt_includeInstance) {
  return proto.external.AssetAggregation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.AssetAggregation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AssetAggregation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    country: jspb.Message.getFieldWithDefault(msg, 3, ""),
    city: jspb.Message.getFieldWithDefault(msg, 4, ""),
    street: jspb.Message.getFieldWithDefault(msg, 5, ""),
    postcode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    type: jspb.Message.getFieldWithDefault(msg, 7, ""),
    portfolioid: jspb.Message.getFieldWithDefault(msg, 8, ""),
    portfolioname: jspb.Message.getFieldWithDefault(msg, 9, ""),
    tenantcount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    occupancy: jspb.Message.getFieldWithDefault(msg, 11, 0),
    glatotal: jspb.Message.getFieldWithDefault(msg, 12, 0),
    alertcount: jspb.Message.getFieldWithDefault(msg, 13, 0),
    lastvaluation: jspb.Message.getFieldWithDefault(msg, 14, 0),
    noi: jspb.Message.getFieldWithDefault(msg, 15, 0),
    yield: jspb.Message.getFieldWithDefault(msg, 16, 0),
    avgrentpersqm: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.AssetAggregation}
 */
proto.external.AssetAggregation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.AssetAggregation;
  return proto.external.AssetAggregation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.AssetAggregation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.AssetAggregation}
 */
proto.external.AssetAggregation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostcode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPortfolioid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPortfolioname(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTenantcount(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOccupancy(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGlatotal(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAlertcount(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastvaluation(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNoi(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYield(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgrentpersqm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.AssetAggregation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.AssetAggregation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.AssetAggregation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AssetAggregation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPostcode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPortfolioid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPortfolioname();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTenantcount();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getOccupancy();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getGlatotal();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getAlertcount();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getLastvaluation();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getNoi();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getYield();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getAvgrentpersqm();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.AssetAggregation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AssetAggregation} returns this
 */
proto.external.AssetAggregation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.external.AssetAggregation.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AssetAggregation} returns this
 */
proto.external.AssetAggregation.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string country = 3;
 * @return {string}
 */
proto.external.AssetAggregation.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AssetAggregation} returns this
 */
proto.external.AssetAggregation.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string city = 4;
 * @return {string}
 */
proto.external.AssetAggregation.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AssetAggregation} returns this
 */
proto.external.AssetAggregation.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string street = 5;
 * @return {string}
 */
proto.external.AssetAggregation.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AssetAggregation} returns this
 */
proto.external.AssetAggregation.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string postcode = 6;
 * @return {string}
 */
proto.external.AssetAggregation.prototype.getPostcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AssetAggregation} returns this
 */
proto.external.AssetAggregation.prototype.setPostcode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.external.AssetAggregation.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AssetAggregation} returns this
 */
proto.external.AssetAggregation.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string portfolioID = 8;
 * @return {string}
 */
proto.external.AssetAggregation.prototype.getPortfolioid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AssetAggregation} returns this
 */
proto.external.AssetAggregation.prototype.setPortfolioid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string portfolioName = 9;
 * @return {string}
 */
proto.external.AssetAggregation.prototype.getPortfolioname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AssetAggregation} returns this
 */
proto.external.AssetAggregation.prototype.setPortfolioname = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 tenantCount = 10;
 * @return {number}
 */
proto.external.AssetAggregation.prototype.getTenantcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.AssetAggregation} returns this
 */
proto.external.AssetAggregation.prototype.setTenantcount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 occupancy = 11;
 * @return {number}
 */
proto.external.AssetAggregation.prototype.getOccupancy = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.AssetAggregation} returns this
 */
proto.external.AssetAggregation.prototype.setOccupancy = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 glaTotal = 12;
 * @return {number}
 */
proto.external.AssetAggregation.prototype.getGlatotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.AssetAggregation} returns this
 */
proto.external.AssetAggregation.prototype.setGlatotal = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 alertCount = 13;
 * @return {number}
 */
proto.external.AssetAggregation.prototype.getAlertcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.AssetAggregation} returns this
 */
proto.external.AssetAggregation.prototype.setAlertcount = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 lastValuation = 14;
 * @return {number}
 */
proto.external.AssetAggregation.prototype.getLastvaluation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.AssetAggregation} returns this
 */
proto.external.AssetAggregation.prototype.setLastvaluation = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 NOI = 15;
 * @return {number}
 */
proto.external.AssetAggregation.prototype.getNoi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.AssetAggregation} returns this
 */
proto.external.AssetAggregation.prototype.setNoi = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 yield = 16;
 * @return {number}
 */
proto.external.AssetAggregation.prototype.getYield = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.AssetAggregation} returns this
 */
proto.external.AssetAggregation.prototype.setYield = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional double avgRentPerSQM = 17;
 * @return {number}
 */
proto.external.AssetAggregation.prototype.getAvgrentpersqm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.AssetAggregation} returns this
 */
proto.external.AssetAggregation.prototype.setAvgrentpersqm = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.PortfoliosAggregationResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.PortfoliosAggregationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.PortfoliosAggregationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.PortfoliosAggregationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.PortfoliosAggregationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    portfoliosList: jspb.Message.toObjectList(msg.getPortfoliosList(),
    proto.external.PortfolioAggregation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.PortfoliosAggregationResponse}
 */
proto.external.PortfoliosAggregationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.PortfoliosAggregationResponse;
  return proto.external.PortfoliosAggregationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.PortfoliosAggregationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.PortfoliosAggregationResponse}
 */
proto.external.PortfoliosAggregationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.external.PortfolioAggregation;
      reader.readMessage(value,proto.external.PortfolioAggregation.deserializeBinaryFromReader);
      msg.addPortfolios(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.PortfoliosAggregationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.PortfoliosAggregationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.PortfoliosAggregationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.PortfoliosAggregationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPortfoliosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.PortfolioAggregation.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PortfolioAggregation portfolios = 1;
 * @return {!Array<!proto.external.PortfolioAggregation>}
 */
proto.external.PortfoliosAggregationResponse.prototype.getPortfoliosList = function() {
  return /** @type{!Array<!proto.external.PortfolioAggregation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.PortfolioAggregation, 1));
};


/**
 * @param {!Array<!proto.external.PortfolioAggregation>} value
 * @return {!proto.external.PortfoliosAggregationResponse} returns this
*/
proto.external.PortfoliosAggregationResponse.prototype.setPortfoliosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.PortfolioAggregation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.PortfolioAggregation}
 */
proto.external.PortfoliosAggregationResponse.prototype.addPortfolios = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.PortfolioAggregation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.PortfoliosAggregationResponse} returns this
 */
proto.external.PortfoliosAggregationResponse.prototype.clearPortfoliosList = function() {
  return this.setPortfoliosList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.PortfolioAggregation.prototype.toObject = function(opt_includeInstance) {
  return proto.external.PortfolioAggregation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.PortfolioAggregation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.PortfolioAggregation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organizationid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    assetcount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    glatotal: jspb.Message.getFieldWithDefault(msg, 5, 0),
    alertcount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    assigned: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.PortfolioAggregation}
 */
proto.external.PortfolioAggregation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.PortfolioAggregation;
  return proto.external.PortfolioAggregation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.PortfolioAggregation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.PortfolioAggregation}
 */
proto.external.PortfolioAggregation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssetcount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGlatotal(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAlertcount(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAssigned(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.PortfolioAggregation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.PortfolioAggregation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.PortfolioAggregation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.PortfolioAggregation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAssetcount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getGlatotal();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getAlertcount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getAssigned();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.PortfolioAggregation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.PortfolioAggregation} returns this
 */
proto.external.PortfolioAggregation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.external.PortfolioAggregation.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.PortfolioAggregation} returns this
 */
proto.external.PortfolioAggregation.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string organizationId = 3;
 * @return {string}
 */
proto.external.PortfolioAggregation.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.PortfolioAggregation} returns this
 */
proto.external.PortfolioAggregation.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 assetCount = 4;
 * @return {number}
 */
proto.external.PortfolioAggregation.prototype.getAssetcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.PortfolioAggregation} returns this
 */
proto.external.PortfolioAggregation.prototype.setAssetcount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 glaTotal = 5;
 * @return {number}
 */
proto.external.PortfolioAggregation.prototype.getGlatotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.PortfolioAggregation} returns this
 */
proto.external.PortfolioAggregation.prototype.setGlatotal = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 alertCount = 6;
 * @return {number}
 */
proto.external.PortfolioAggregation.prototype.getAlertcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.PortfolioAggregation} returns this
 */
proto.external.PortfolioAggregation.prototype.setAlertcount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool assigned = 7;
 * @return {boolean}
 */
proto.external.PortfolioAggregation.prototype.getAssigned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.external.PortfolioAggregation} returns this
 */
proto.external.PortfolioAggregation.prototype.setAssigned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetTotalsByTypesRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetTotalsByTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetTotalsByTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetTotalsByTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTotalsByTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    portfolioidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    assetidsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTotalsByTypesRequest}
 */
proto.external.GetTotalsByTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTotalsByTypesRequest;
  return proto.external.GetTotalsByTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTotalsByTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTotalsByTypesRequest}
 */
proto.external.GetTotalsByTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addPortfolioids(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addAssetids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTotalsByTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTotalsByTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTotalsByTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTotalsByTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPortfolioidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getAssetidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string portfolioIDs = 1;
 * @return {!Array<string>}
 */
proto.external.GetTotalsByTypesRequest.prototype.getPortfolioidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.external.GetTotalsByTypesRequest} returns this
 */
proto.external.GetTotalsByTypesRequest.prototype.setPortfolioidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.external.GetTotalsByTypesRequest} returns this
 */
proto.external.GetTotalsByTypesRequest.prototype.addPortfolioids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetTotalsByTypesRequest} returns this
 */
proto.external.GetTotalsByTypesRequest.prototype.clearPortfolioidsList = function() {
  return this.setPortfolioidsList([]);
};


/**
 * repeated string assetIDs = 2;
 * @return {!Array<string>}
 */
proto.external.GetTotalsByTypesRequest.prototype.getAssetidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.external.GetTotalsByTypesRequest} returns this
 */
proto.external.GetTotalsByTypesRequest.prototype.setAssetidsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.external.GetTotalsByTypesRequest} returns this
 */
proto.external.GetTotalsByTypesRequest.prototype.addAssetids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetTotalsByTypesRequest} returns this
 */
proto.external.GetTotalsByTypesRequest.prototype.clearAssetidsList = function() {
  return this.setAssetidsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetTotalsByTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetTotalsByTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetTotalsByTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTotalsByTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    portfolioCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    assetCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    tenantCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    unitsCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    gla: jspb.Message.getFieldWithDefault(msg, 5, 0),
    occupancy: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTotalsByTypesResponse}
 */
proto.external.GetTotalsByTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTotalsByTypesResponse;
  return proto.external.GetTotalsByTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTotalsByTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTotalsByTypesResponse}
 */
proto.external.GetTotalsByTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPortfolioCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAssetCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTenantCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUnitsCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGla(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOccupancy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTotalsByTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTotalsByTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTotalsByTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTotalsByTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPortfolioCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAssetCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTenantCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getUnitsCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getGla();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOccupancy();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 portfolio_count = 1;
 * @return {number}
 */
proto.external.GetTotalsByTypesResponse.prototype.getPortfolioCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.GetTotalsByTypesResponse} returns this
 */
proto.external.GetTotalsByTypesResponse.prototype.setPortfolioCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 asset_count = 2;
 * @return {number}
 */
proto.external.GetTotalsByTypesResponse.prototype.getAssetCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.GetTotalsByTypesResponse} returns this
 */
proto.external.GetTotalsByTypesResponse.prototype.setAssetCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 tenant_count = 3;
 * @return {number}
 */
proto.external.GetTotalsByTypesResponse.prototype.getTenantCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.GetTotalsByTypesResponse} returns this
 */
proto.external.GetTotalsByTypesResponse.prototype.setTenantCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 units_count = 4;
 * @return {number}
 */
proto.external.GetTotalsByTypesResponse.prototype.getUnitsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.GetTotalsByTypesResponse} returns this
 */
proto.external.GetTotalsByTypesResponse.prototype.setUnitsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 gla = 5;
 * @return {number}
 */
proto.external.GetTotalsByTypesResponse.prototype.getGla = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.GetTotalsByTypesResponse} returns this
 */
proto.external.GetTotalsByTypesResponse.prototype.setGla = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 occupancy = 6;
 * @return {number}
 */
proto.external.GetTotalsByTypesResponse.prototype.getOccupancy = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.GetTotalsByTypesResponse} returns this
 */
proto.external.GetTotalsByTypesResponse.prototype.setOccupancy = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


goog.object.extend(exports, proto.external);
