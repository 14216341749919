import React from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import initSentry from './config/sentry';
import theme from './config/theme';
import store from './store/store';
import './index.css';

import { LicenseManager } from "ag-grid-enterprise";

LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-071626}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Recognyte_Limited}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Recognyte}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Recognyte}_need_to_be_licensed___{Recognyte}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{13_November_2025}____[v3]_[01]_MTc2Mjk5MjAwMDAwMA==e122faa4586f7b3df9d9568083177944");

initSentry();
ReactDOM.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ToastContainer position="bottom-left" hideProgressBar autoClose={5000} maxSnack={5} />
          <CssBaseline />
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
  document.getElementById('root')
);

if (window.Cypress) {
  window.store = store;
}
