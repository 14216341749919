import React from 'react';

import {Box, TextField} from '@mui/material';
import {closeModal} from 'components/Modal/modalReducer';
import ModalWrapper from 'components/Modal/ModalWrapper';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import * as actions from 'store/actions';
import useStyles from 'styles/forms';
import DatePicker from 'components/DatePicker/KeyboardPicker';
import {actualsCategories} from "../../../../constants";
import Autocomplete from '@mui/material/Autocomplete';
import useRouter from "../../../../hooks/useRouter";

const getForm = () => ({
    date: new Date(),
    company: '',
    debit: 0,
    credit: 0,
    category: 0,
    subcategory: ''
});

const AddActual = () => {
    const classes = useStyles();
    const [form, setForm] = React.useState(getForm());
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.tenants.loading);
    const {data: asset} = useSelector((state) => state.assets.assetDetails);
    const {query} = useRouter();

    const [options, setOptions] = React.useState([]);

    React.useEffect(() => {
        setOptions([]);
    }, []);

    const handleSave = () => {
        if (!form.date || !form.company || !form.category || !form.subcategory) {
            return toast.error('Please fill in the form!');
        }

        return dispatch(actions.createActual(asset.id, form))
            .then(() => {
                toast.success('Success');
                setForm(getForm());
                dispatch(closeModal());
                dispatch(actions.getActualsList(asset.id));
            })
            .catch((err) => {
                console.error(err)
                toast.error(err);
            });
    };

    const handleChange = (name, value) => {
        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        (<ModalWrapper
            size="xl"
            header={'Create Actual'}
            handleSave={handleSave}
            loading={loading}
        >
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                <DatePicker
                    label="Date"
                    format="DD/MM/YYYY"
                    name="date"
                    id="date"
                    selectedDate={form.date}
                    setSelectedDate={(e) => handleChange('date', e)}
                />
                <TextField
                    fullWidth
                    label="Company"
                    variant="outlined"
                    className={classes.textField}
                    value={form.company}
                    disabled={loading}
                    onChange={(e) => handleChange('company', e.target.value)}
                    id="company"
                    slotProps={{
                        input: {
                            classes: {
                                input: classes.input
                            }
                        }
                    }}
                />
                <TextField
                    fullWidth
                    label="Expense"
                    variant="outlined"
                    className={classes.textField}
                    value={form.debit}
                    disabled={loading}
                    onChange={(e) => handleChange('debit', e.target.value)}
                    id="debit"
                    slotProps={{
                        input: {
                            classes: {
                                input: classes.input
                            }
                        }
                    }}
                />
                <TextField
                    fullWidth
                    label="Income"
                    variant="outlined"
                    className={classes.textField}
                    value={form.credit}
                    disabled={loading}
                    onChange={(e) => handleChange('credit', e.target.value)}
                    id="credit"
                    slotProps={{
                        input: {
                            classes: {
                                input: classes.input
                            }
                        }
                    }}
                />

                <Autocomplete
                    id="category"
                    options={actualsCategories}
                    getOptionLabel={(option) => option.label}
                    className={classes.autocomplete}
                    autoComplete
                    autoHighlight
                    clearOnEscape
                    value={[...actualsCategories].find((type) => type.id === form.category) || null}
                    onChange={(_, value) => {
                        handleChange('category', value?.id)
                        dispatch(actions.getSubcategoriesList(value?.id, asset.id)).then((e) => {
                            setOptions(e)
                        }).catch((e) => console.error(e))
                    }}
                    renderInput={(params) => (
                        <TextField {...params} required label="Category" variant="outlined"/>
                    )}
                />
                <Autocomplete
                    id="subcategory"
                    options={options}
                    className={classes.autocomplete}
                    autoComplete
                    autoHighlight
                    clearOnEscape
                    value={[...options].find((type) => type === form.subcategory) || null}
                    onChange={(_, value) => handleChange('subcategory', value)}
                    renderInput={(params) => (
                        <TextField {...params} required label="Subcategory" variant="outlined"/>
                    )}
                />
            </Box>
        </ModalWrapper>)
    );
};

export default AddActual;
