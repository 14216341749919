import React, {useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ApexChart from 'components/Charts';
import {numberWithCommas} from 'helpers/utils';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';

import {config} from './chartConfig';
import useStyles from './styles';

const GraphCard = ({dataCy, to, from, totalValue, prevTotalValue, series, name, period, year}) => {
    const classes = useStyles();

    let defaultOptions = {...config.options}
    if (name === 'Tenants' || name === 'Occupancy') {
        defaultOptions = {
            ...defaultOptions,
            yaxis: {
                ...defaultOptions.yaxis,
                labels: {
                    formatter: (value) => `${numberWithCommas(value)}`
                }
            }
        };
    }

    const [options, setOptions] = useState(defaultOptions);

    useEffect(() => {
        if (period === 'year') {
            setOptions(prevOptions => ({
                ...prevOptions,
                xaxis: {
                    ...prevOptions.xaxis,
                    categories: new Array(5).fill().map((_, i) => year + i)
                }
            }));

        } else {
            setOptions(prevOptions => ({
                ...prevOptions,
                xaxis: {
                    ...prevOptions.xaxis,
                    categories: [
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sep',
                        'Oct',
                        'Nov',
                        'Dec'
                    ]
                }
            }));
        }
    }, [period, year]);

    let nameWidget = (<Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle1" style={{color: '#555'}} className={classes.chartName}>
            {name}
        </Typography>
        <MoreVertIcon style={{color: '#666'}}/>
    </Box>);
    if (to) {
        nameWidget = (
            <NavLink
                to={{
                    pathname: to,
                    search: from
                }}
                className={classes.navLink}
            >
                {nameWidget}
            </NavLink>
        );
    }

    return (
        <Box data-cy={dataCy}>
            <Paper className={classes.paper}>
                <Box style={{padding: '10px 20px'}}>
                    {nameWidget}
                    <Box className={classes.wrapCard}>
                        <Box className={classes.wrapHeading}>
                            <Typography variant="h6">{totalValue != null ? `€${numberWithCommas(totalValue)}` : <br/>}</Typography>
                            <Typography className={classes.fromText}>
                                {prevTotalValue != null ? `from €${numberWithCommas(prevTotalValue)}` : " "}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <ApexChart key={JSON.stringify(options)} options={options} series={series}/>
            </Paper>
        </Box>
    );
};

export default GraphCard;

GraphCard.defaultProps = {
    totalValue: 0,
    prevTotalValue: 0,
    name: '',
    series: [],
    dataCy: ''
};

GraphCard.propTypes = {
    totalValue: PropTypes.number,
    prevTotalValue: PropTypes.number,
    name: PropTypes.string,
    series: PropTypes.arrayOf(PropTypes.object),
    dataCy: PropTypes.string,
    to: PropTypes.string,
    from: PropTypes.string.isRequired
};
