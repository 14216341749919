/* eslint-disable function-paren-newline */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {lazy, Suspense, useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Spinner from 'components/Spinner';
import useRouter from 'hooks/useRouter';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from 'store/actions';

import SideMenu from './SideMenu';
import useStyles from './styles';
import TenantHeader from "../../Tenants/TenantDetails/TenantHeader/Header";
import Paper from "@mui/material/Paper";
import Tabs from "../../../components/Tabs/Tabs";
import Divider from "@mui/material/Divider";
import TabPanel from "../../../components/Tabs/TabPanel/TabPanel";
import LeasingTenantDetail
    from "../../../Workflows/Tenant/LeasingManager/LeasingManagerTabs/LeasingTenantDetails/LeasingTenantDetails";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const LeasingStrategy = lazy(() =>
    import('Workflows/Tenant/LeasingManager/LeasingManagerTabs/LeasingStrategy')
);
const TenantOptions = lazy(() =>
    import('Workflows/Tenant/LeasingManager/LeasingManagerTabs/LeasingTenantOptions')
);
const ContractClauses = lazy(() =>
    import('Workflows/Tenant/LeasingManager/LeasingManagerTabs/LeasingContractDetails')
);
const Reports = lazy(() => import('views/Tenants/TenantDetails/Reports'));

const AssetsTenantDetails = () => {
    const {query, push} = useRouter();
    const dispatch = useDispatch();
    const classes = useStyles();
    const {contract: contracts} = useSelector((state) => state.leasingManager);
    const {loading, loaded, error} = useSelector((state) => state.tenants.tenantDetails);
    const [value, setValue] = useState(0);

    useEffect(() => {
        if (!query.id) {
            return;
        }

        dispatch(actions.getTenantDetails(query.id)).then((res) => {
            dispatch(actions.getUnits(res.assetid));
            dispatch(actions.getAssetDetails(res.assetid));
        });
        dispatch(actions.getContractById(query.id));
    }, [query.id]);

    useEffect(() => {
        if (!contracts[0]?.id) return;
        dispatch(actions.getTenantLeasingDetails(contracts[0]?.id));
    }, [contracts[0]?.id]);

    if (loading || !loaded) {
        return <Spinner/>;
    }

    if (error && loaded) {
        return (
            <Box m={4}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box className={classes.container}>
            <Box maxWidth="210px" minWidth="210px">
                <SideMenu/>
            </Box>
            <Box width="100%" className={classes.scrollable}>
                <Box>
                        <IconButton
                            onClick={() => push(`/Asset/${query.assetID}/Tenants`)}
                            color="primary"
                            size="medium"
                        >
                            <ArrowBackIcon color="primary" fontSize="large" />
                        </IconButton>
                    <TenantHeader/>
                    <br/>

                    <Paper className={classes.root}>
                        <Tabs
                            value={value}
                            handleChange={(_, newValue) => setValue(newValue)}
                            tabNames={['Details', 'Strategy', 'Break Options', 'Contract Clauses', 'Cashflow']}
                        />
                    </Paper>
                    <Divider/>
                    <Suspense fallback={<Spinner mini/>}>
                        <TabPanel value={value} index={0}>
                            <LeasingTenantDetail/>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <LeasingStrategy/>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <TenantOptions/>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <ContractClauses/>
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            <Reports/>
                        </TabPanel>
                    </Suspense>
                </Box>
            </Box>
        </Box>
    );
};

export default AssetsTenantDetails;
