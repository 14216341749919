import React, { useState } from 'react';

import Box from '@mui/material/Box';
import TabPanel from '../../../components/Tabs/TabPanel/TabPanel';
import Report from './Report';
import Tab from '@mui/material/Tab';
import { TabContext, TabList } from '@mui/lab';
import useRouter from '../../../hooks/useRouter';
import { useDispatch, useSelector } from 'react-redux';

const namesMapper = [
  'Base Rent',
  'Turnover Rent',
  'Service Charge',
  'Other Income',
  'Cumulative Rent',
  'NOI',
  'Opex',
  'Capex',
  'Other Expenses',
  'Leasing Fee',
  'Fit Out',
  'MLA'
];

const AssetChartDetails = () => {
  const [tab, setTab] = useState(0);

  const handleChange = (_, newValue) => {
    setTab(newValue);
  };

  return (
    <TabContext value={tab}>
      <Box sx={{ borderBottom: 1, borderColor: '#D6E2FD', borderWidth: '0.5px' }}>
        <TabList
          onChange={handleChange}
          scrollButtons="auto"
          variant="scrollable"
        >
          {namesMapper.map((item, index) => (
            <Tab label={item} value={index} />
          ))}
        </TabList>
      </Box>

      {namesMapper.map((item, index) => (
        <TabPanel key={index} value={tab} index={index}>
          <Report tab={item} />
        </TabPanel>
      ))}
    </TabContext>
  );
};
export default AssetChartDetails;
