import Box from '@mui/material/Box';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid2, Paper } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  iconWhite: {
    color: '#FFF'
  },
  colorBox: {
    backgroundColor: '#3379FF',
    borderRadius: '5px',
    width: '64px',
    height: '64px',
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
  },
  marginRight: {
    margin: '0 48px 0 0'
  },
  paddingLeft: {
    padding: '0 0 0 8px'
  },
  marginBottom: {
    margin: '0 0 16px 0'
  },
  paddingY: {
    padding: theme.spacing(2, 0)
  },
  boxHeading: {
    margin: theme.spacing(1, 0),
    color: '#666'
  },
  color: { color: '#666' },
  box: {
    textDecoration: 'none'
  }
}));

const BoxItem = ({ icon: Icon, count, name, to }) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.marginRight, classes.box)}
      component={Link}
      to={to}
    >
      <Paper elevation={2}>
        <Grid2 container>
          <Grid2 item size={3}>
            <Box className={classes.colorBox} display="flex" justifyContent="center" alignItems="center">
              <Icon className={classes.iconWhite} fontSize="large" />
            </Box>
          </Grid2>
          <Grid2 item container size={9} sx={{alignItems:"center"}}>
            <Grid2 item size={12}>
              <Typography variant="body1" sx={{textAlign:"center", color:'#3379FF', fontWeight: 500}}>
                {count}
              </Typography>
            </Grid2>
            <Grid2 item size={12}>
              <Typography variant="subtitle2" sx={{textAlign:"center", fontWeight: 500}}>
                {name}
              </Typography>
            </Grid2>
          </Grid2>
        </Grid2>
      </Paper>
    </Box>
  );
};

BoxItem.propTypes = {
  icon: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

export default BoxItem;