import React, { useCallback, useMemo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions';

import useStyles from './styles';
import { Grid2 } from '@mui/material';
import Divider from '@mui/material/Divider';

// alert types
const ALERT_TYPE_TENANT_CONTRACT_EXPIRATION = 1;
const ALERT_TYPE_VACANT_UNITS = 3;
const ALERT_TYPE_TENANT_CONTRACT_BREAK = 4;
const ALERT_TYPE_OPEX_CONTRACT_EXPIRATION = 5;

// category types
const ALERT_CATEGORY_ASSET_MANAGEMENT = 1;
const ALERT_CATEGORY_PROPERTY_MANAGEMENT = 2;

const Tasks = () => {
  const classes = useStyles();
  const { alerts } = useSelector((state) => state.alerts);
  const dispatch = useDispatch();

  const assetAlerts = useMemo(
    () => alerts.filter((alert) => alert.category === ALERT_CATEGORY_ASSET_MANAGEMENT),
    [alerts]
  );
  const propertyAlerts = useMemo(
    () => alerts.filter((alert) => alert.category === ALERT_CATEGORY_PROPERTY_MANAGEMENT),
    [alerts]
  );

  const getFilteredData = useCallback(
    (items, type) => items.filter((alert) => alert.type === type),

    [assetAlerts, propertyAlerts]
  );

  const openAlerts = (name) => {
    dispatch(actions.toggleAlertMenu());
    dispatch(actions.showTask(name));
  };

  return (
    <Grid2 container sx={{
      background: '#F7F8FD',
      borderRadius: '12px'
    }}>
      <Grid2 item size={12} sx={{borderBottom: '1px solid #4A445929'}}>
        <Typography variant="h6" sx={{padding: '20px'}}>
          Tasks
        </Typography>
      </Grid2>
      <Grid2 item size={6}>
        <Box className={classes.task} onClick={() => openAlerts('vacant_units')}>
          <Typography
            data-cy="vacant-units-text"
            variant="subtitle1"
            className={classes.fontLight}
          >
            Vacant units
          </Typography>
          <Typography
            data-cy="vacant-units-count"
            variant="subtitle1"
            className={classes.fontLight}
          >
            {getFilteredData(assetAlerts, ALERT_TYPE_VACANT_UNITS).length}
          </Typography>
        </Box>
      </Grid2>
      <Grid2 item size={6}>
        <Box className={classes.task} onClick={() => openAlerts('lease_expiry')}>
          <Typography
            data-cy="lease-expires-text"
            variant="subtitle1"
            className={classes.fontLight}
          >
            Upcoming lease expires
          </Typography>
          <Typography
            data-cy="lease-expires-count"
            variant="subtitle1"
            className={classes.fontLight}
          >
            {getFilteredData(assetAlerts, ALERT_TYPE_TENANT_CONTRACT_EXPIRATION).length}
          </Typography>
        </Box>
      </Grid2>
      <Grid2 item size={6}>
        <Box className={classes.task} onClick={() => openAlerts('lease_break')}>
          <Typography
            data-cy="lease-break-text"
            variant="subtitle1"
            className={classes.fontLight}
          >
            Upcoming lease breaks
          </Typography>
          <Typography
            data-cy="lease-break-count"
            variant="subtitle1"
            className={classes.fontLight}
          >
            {getFilteredData(assetAlerts, ALERT_TYPE_TENANT_CONTRACT_BREAK).length}
          </Typography>
        </Box>
      </Grid2>
      <Grid2 item size={6}>
        <Box className={classes.task} onClick={() => openAlerts('contract_expiry')}>
          <Typography data-cy="opex-text" variant="subtitle1" className={classes.fontLight}>
            Opex contracts expires
          </Typography>
          <Typography data-cy="opex-count" variant="subtitle1" className={classes.fontLight}>
            {getFilteredData(propertyAlerts, ALERT_TYPE_OPEX_CONTRACT_EXPIRATION).length}
          </Typography>
        </Box>
      </Grid2>
      <Grid2 item size={6}>
        <Box className={classes.task} onClick={() => openAlerts('capex_works')}>
          <Typography data-cy="capex-text" variant="subtitle1" className={classes.fontLight}>
            Capex works underway
          </Typography>
          <Typography data-cy="capex-count" variant="subtitle1" className={classes.fontLight}>
            0
          </Typography>
        </Box>
      </Grid2>
    </Grid2>
  );
};

export default Tasks;
