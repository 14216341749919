import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';

const RootPaper = styled(Paper)`
  border-color: #D6E2FD;
  border-width: 0.5px;
  border-style: double;
  border-radius: 12px;
`

export default RootPaper;