import React, { useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { closeModal, openModal } from 'components/Modal/modalReducer';
import useRouter from 'hooks/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';
import LeasingManagerHeader from 'Workflows/Tenant/LeasingManager/LeasingManagerHeader';
import RootPaper from '../../../../styles/rootPaper';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import { Grid2 } from '@mui/material';
import Avatar from '@mui/material/Avatar';

const makeBundleId = (portfolioIds) => `Page1-PortfolioIds${portfolioIds}`;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse'
    }
  },
  hideMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  editIcon: {
    color: '#7D7D7D',
    cursor: 'pointer',
    marginRight: 12,
    fontSize: 26
  },
  headerText: {
    fontFamily: theme.fonts.baseSerif,
    fontSize: 30,
    color: theme.palette.common.black,
    fontWeight: 500
  },
  root: {
    marginBottom: 16,
    padding: theme.spacing(1)
  },
  icon: {
    fontSize: 18,
    color: theme.palette.text.lightgrey
  },
  subtitle: {
    color: theme.palette.text.grey,
    paddingLeft: theme.spacing(1),
    fontFamily: theme.fonts.openSans,
    fontSize: 14,
    marginLeft: theme.spacing(1.5)
  },
  tag: {
    color: theme.palette.text.lightgrey,
    marginLeft: theme.spacing(2),
    fontSize: 18
  },
  link: {
    fontSize: 22,
    marginLeft: theme.spacing(2)
  },
  padding: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(2)
  },
  spaceBetween: {
    justifyContent: 'space-between'
  }
}));

export default function Header() {
  const router = useRouter();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { data } = useSelector((state) => state.tenants.tenantDetails);
  const [open, setOpen] = useState(false);
  const { units } = useSelector((state) => state.leasingManager);
  const { contract: contracts } = useSelector((state) => state.leasingManager);
  const { details } = useSelector((state) => state.leasingManager.details);

  const getUnitsData = useMemo(() => {
    const filteredUnits = units?.filter((unit) => details.unitidsList?.indexOf(unit.id) > -1);
    const unitsNames = filteredUnits?.map((unit) => unit.name).join(', ');
    const unitsFloors = filteredUnits?.map((unit) => unit.floor).join(', ');
    const unitsTotalGLA = filteredUnits?.reduce((acc, unit) => acc + unit.gla, 0).toFixed(2);

    return {
      unitsNames,
      unitsFloors,
      unitsTotalGLA
    };
  }, [units, details]);

  useEffect(() => {
    dispatch(actions.getTenantLeasingDetails(contracts[0]?.id));
  }, []);

  const handleDelete = () => {
    dispatch(actions.deleteTenant(router.query.id))
      .then(() => {
        dispatch(actions.getTenants([], makeBundleId([]), [], true));
        toast.success('Tenant Deleted');
      })
      .then(() => {
        router.history.goBack();
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleConfirm = () => {
    dispatch(
      openModal({
        modalType: 'AlertModal',
        modalProps: {
          title: 'Delete Tenant',
          content:
            'Are you sure you want to delete the selected Tenant? This action cannot be undone',
          handleConfirm: () => {
            handleDelete();
            dispatch(closeModal());
          }
        }
      })
    );
  };

  const HandleAddContract = (form) => {
    const formCopy = {
      unitidsList: form.unitidsList,
      assetid: data.assetid,
      number: form.number,
      tenantid: data.id,
      startdate: form.startdate,
      enddate: form.enddate
    };

    dispatch(actions.createLeasingContract(formCopy))
      .then(() => {
        toast.success('Contract Added');
        setOpen(false);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const icons = [
    {
      icon: WindowOutlinedIcon,
      text: 'Unit Name',
      value: getUnitsData?.unitsNames || 'N/A'
    },
    {
      icon: ViewQuiltOutlinedIcon,
      text: 'Total GLA',
      value: `${getUnitsData?.unitsTotalGLA} m2`
    },
    {
      icon: StoreMallDirectoryOutlinedIcon,
      text: 'Floor',
      value: getUnitsData?.unitsFloors || 'N/A'
    },
    {
      icon: MonetizationOnOutlinedIcon,
      text: 'Contract Reference',
      value: data.tradingname
    },
    {
      icon: MonetizationOnOutlinedIcon,
      text: 'Industry',
      value: data.industry
    },
    {
      icon: TodayOutlinedIcon,
      text: 'Started',
      value: data?.startdate
    },
    {
      icon: TodayOutlinedIcon,
      text: 'Ends',
      value: data?.enddate
    }
  ];

  return (
    <RootPaper sx={{ padding: '20px' }}>
      <Grid2 container spacing={0} rowSpacing={5} columns={14}>
        <Grid2 item size={6}>
          <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
            <Typography variant="h4" sx={{
              fontWeight: 400
            }}>
              {data.companyname}
            </Typography>
            <IconButton aria-label="edit" onClick={() =>
              dispatch(
                openModal({
                  modalType: 'TenantModal',
                  modalProps: { isEdit: true, assetid: data.assetid }
                })
              )
            }>
              <EditIcon sx={{ color: '#3379FF' }} />
            </IconButton>
            <IconButton aria-label="delete" onClick={handleConfirm}>
              <DeleteIcon sx={{ color: '#000' }} />
            </IconButton>
          </Box>
        </Grid2>
        <Grid2 item spacing={{lg: 3, xl: 2}} offset={{lg: 5, xl: 6}}>
          <LeasingManagerHeader
            unitList={units}
            HandleAddContract={HandleAddContract}
            show={open}
            setShow={setOpen}
          />
        </Grid2>
        {icons.map((icon, index) => (
          <Grid2 item container size={2} spacing={6} justifyContent="center"
                 alignItems="center">
            <Grid2 size={2}>
              <Avatar sx={{ bgcolor: '#D6E2FD', color: '#3379FF' }}>
                <icon.icon />
              </Avatar>
            </Grid2>
            <Grid2 size={10}>
              <Typography variant="body2" color="text.secondary">
                {icon.text}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>{icon.value}</Typography>
            </Grid2>
          </Grid2>
        ))}
      </Grid2>
    </RootPaper>
  );
}
