import { useScript } from '../../helpers/utils';
import { useRouteMatch } from 'react-router-dom';

const ADA = () => {
  const isMatch = Boolean(useRouteMatch('/ADA'))

  useScript('https://api.mindstudio.ai/v1/embed.js');

  return (
    <iframe hidden={!isMatch} id="mindstudio-frame" referrerPolicy="origin" width="100%" height="800px"
            src="https://app.mindstudio.ai/_embeds/app?__displayContext=embedded&__controlledAuth=1"></iframe>
  );
};

export default ADA;

