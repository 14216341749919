import { facilityStatus } from 'constants/index';

import React from 'react';

import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import CancelButton from 'components/Buttons/CancelButton';
import SaveButton from 'components/Buttons/SaveButton';
import DatePicker from 'components/DatePicker/FormikPicker';
import TextInput from 'components/Input/TextInput';
import ModalWrapper from 'components/Modal/FormikModalWrapper';
import { closeModal } from 'components/Modal/modalReducer';
import { addDays } from 'date-fns';
import { Formik } from 'formik';
import { lowerCaseAllWordsExceptFirstLetters } from 'helpers/utils';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions';
import useStyles from 'styles/forms';
import * as Yup from 'yup';

const minDate = addDays(new Date(), 1);

const getForm = (form) => ({
  location: '',
  createddate: new Date(),
  duedate: minDate,
  status: 0,
  userid: '',
  caseref: '',
  queryname: '',
  description: '',
  id: '',
  ...form
});

const validationSchema = Yup.object({
  location: Yup.string(),
  createddate: Yup.date(),
  duedate: Yup.date().required('Due Date is Required!'),
  status: Yup.number().required('Status is Required'),
  userid: Yup.string().required('Assigned to is Required!'),
  caseref: Yup.string(),
  queryname: Yup.string().required('Query is Required'),
  description: Yup.string(),
  id: Yup.string()
});

const AddFacilityManager = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);
  const { handleSave, facilityManager, header } = useSelector((state) => state.modals.modalProps);

  return (
    <ModalWrapper size="md" header={facilityManager?.id ? `Edit ${header}` : `New ${header}`} xl>
      <Formik
        initialValues={getForm(facilityManager)}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          const formToSubmit = Object.keys(values).reduce(
            (acc, key) => {
              if (facilityManager[key] !== values[key]) {
                return { ...acc, [key]: values[key] };
              }
              return { ...acc };
            },
            { id: values.id }
          );

          handleSave(formToSubmit)
            .catch(() => {
              setSubmitting(false);
            })
            .finally(() => {
              dispatch(actions.getAlertsList());
            });
        }}
      >
        {({ values, isSubmitting, dirty, isValid, touched, errors, submitForm, handleChange }) => (
          <>
            <DialogContent>
              <TextInput
                required
                label="Query"
                large
                disabled={isSubmitting}
                name="queryname"
                value={values.queryname}
                onChange={handleChange}
                error={touched.queryname && Boolean(errors.queryname)}
                helperText={touched.queryname && errors.queryname}
              />

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className={classes.box}
              >
                <DatePicker
                  label="Date Due"
                  format="DD/MM/YYYY"
                  name="duedate"
                  id="duedate"
                  minDate={values.createddate}
                />

                {users && users.length > 0 && (
                  <TextInput
                    large
                    label="Assigned to"
                    InputProps={{
                      classes: {
                        input: classes.selectInput
                      }
                    }}
                    required
                    SelectProps={{
                      MenuProps: {
                        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                        transformOrigin: { vertical: 'top', horizontal: 'center' },
                        getContentAnchorEl: null,
                        classes: {
                          paper: classes.selectTextfieldWrapper
                        }
                      }
                    }}
                    disabled={isSubmitting}
                    name="userid"
                    value={values.userid}
                    onChange={handleChange}
                    error={touched.userid && Boolean(errors.userid)}
                    helperText={touched.userid && errors.userid}
                    select
                    style={{ width: '47%', minHeight: 61 }}
                  >
                    {users.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option.id}
                        classes={{ root: classes.menuItem, selected: classes.selected }}
                      >
                        <Typography>{option.email.substring(0, 40)}</Typography>
                      </MenuItem>
                    ))}
                  </TextInput>
                )}
              </Box>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className={classes.box}
              >
                <TextInput
                  large
                  label="Location"
                  disabled={isSubmitting}
                  name="location"
                  value={values.location}
                  onChange={handleChange}
                  error={touched.location && Boolean(errors.location)}
                  helperText={touched.location && errors.location}
                  style={{ width: '47%' }}
                />
                <TextInput
                  label="Status"
                  large
                  disabled={isSubmitting}
                  name="status"
                  value={values.status}
                  onChange={handleChange}
                  error={touched.status && Boolean(errors.status)}
                  helperText={touched.status && errors.status}
                  select
                  style={{ width: '47%' }}
                >
                  {facilityStatus.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.id}
                      classes={{
                        root: classes.menuItem,
                        selected: classes.selected
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <div
                          className={classes.circle}
                          style={
                            option.id === 0 ? { background: '#01B231' } : { background: '#D9D9D9' }
                          }
                        />
                        <Typography
                          style={option.id === 0 ? { color: '#01B231' } : { color: '#AAAAAA' }}
                        >
                          {lowerCaseAllWordsExceptFirstLetters(option.name)}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </TextInput>
              </Box>

              <TextInput
                label="Description"
                large
                disabled={isSubmitting}
                name="description"
                value={values.description}
                onChange={handleChange}
                error={touched.description && Boolean(errors.description)}
                helperText={touched.description && errors.description}
                rows={5}
                multiline
              />
            </DialogContent>
            <DialogActions>
              <Box p={2} display="flex">
                <CancelButton onClick={() => dispatch(closeModal())} disabled={isSubmitting} />
                <SaveButton
                  type="submit"
                  loading={isSubmitting}
                  disabled={!dirty || isSubmitting || !isValid}
                  onClick={submitForm}
                  data-testid="addfacility-save"
                />
              </Box>
            </DialogActions>
          </>
        )}
      </Formik>
    </ModalWrapper>
  );
};

export default AddFacilityManager;
