import { useDispatch, useSelector } from 'react-redux';
import useRouter from '../../../hooks/useRouter';
import React, { Suspense, useEffect } from 'react';
import * as actions from '../../../store/actions';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Spinner from '../../../components/Spinner/Spinner';
import { Grid2 } from '@mui/material';
import Paper from '@mui/material/Paper';
import ApexChart from '../../../components/Charts/ApexChart';
import { config } from '../../HomeScreen/chartConfig';
import TableSummary from '../../HomeScreen/ChartDetails/TableSummary';
import TableActualsForecasts from '../../HomeScreen/ChartDetails/TableActualsForecasts';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextWithPadding from '../../../styles/textWithPadding';

const Report = ({ tab }) => {
  const [year, setYear] = React.useState(new Date());
  const { data, error, loaded } = useSelector((state) => state.home.chartDataPage);
  const dispatch = useDispatch();
  const {
    data: summaryData,
    loading: summaryDataLoading,
    loaded: summaryDataLoaded
  } = useSelector((state) => state.home.summaryData);
  const router = useRouter();
  const { data: forecastsData } = useSelector((state) => state.home.forecasts);
  const { data: actualsData } = useSelector((state) => state.home.actuals);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'scroll';
    };
  }, []);

  useEffect(() => {
    const page = tab.replaceAll(' ', '');

    dispatch(actions.getFilterYears());
    dispatch(
      actions.getChartDataPerPage(page, { PortfolioID: [], AssetID: [router.query.id], Year: [year.getFullYear()] })
    );
    dispatch(
      actions.getSummaryTableData(page, { PortfolioID: [], AssetID: [router.query.id], Year: [year.getFullYear()] })
    );
    dispatch(actions.getTenantsActuals(page, {
      PortfolioID: [],
      AssetID: [router.query.id],
      Year: [year.getFullYear()]
    }));
    dispatch(
      actions.getTenantsForecasts(page, { PortfolioID: [], AssetID: [router.query.id], Year: [year.getFullYear()] })
    );
  }, [tab, year]);

  if (error && loaded) {
    return (
      <Typography color="error" sx={{ marginLeft: 30, marginTop: 30 }}>
        {error}
      </Typography>
    );
  }

  return (
    <>
      <Paper sx={{ padding: '20px' }}>
        <Grid2 container spacing={5} wrap="wrap" sx={{ marginBottom: 2 }}>
          <Grid2 size={{ lg: 5 }}>
            <Paper elevation={2} sx={{ padding: '10px 20px', borderRadius: '4px' }}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h5">{tab}</Typography>
              </Box>
              <Suspense fallback={<Spinner mini />}>
                <ApexChart
                  height={300}
                  options={config.options}
                  series={[
                    {
                      name: `${tab} Forecasts`,
                      data: (tab === 'MLA' ? summaryData?.mlasList : summaryData?.totalforecastsList) || []
                    },
                    {
                      name: `${tab} Actuals`,
                      data: data?.actualforecastsList || []
                    }
                  ]}
                />
              </Suspense>
            </Paper>
          </Grid2>
          <Grid2 size={{ lg: 4 }}>
            <DatePicker label={'Year'} openTo="year" onChange={(value) => {
              setYear(value);
              dispatch(actions.setYearFilter(value.getFullYear()));
            }} views={['year']} maxDate={new Date()}
                        minDate={new Date(2018, (new Date()).getMonth(), (new Date()).getDate())}
                        value={year} />
          </Grid2>
        </Grid2>

        {summaryData && (
          <TableSummary
            data={summaryData || null}
            loading={summaryDataLoading}
            loaded={summaryDataLoaded}
            tab={tab}
          />
        )}
      </Paper>
      <br></br>
      <Paper elevation={3} sx={{ overflowX: 'scroll' }}>
        <TextWithPadding>Forecasts</TextWithPadding>
        <TableActualsForecasts
          data={forecastsData || []}
          name="forecastsList"
          tab={tab}
          assetId={router.query.id}
        />
      </Paper>
      <br></br>
      <Paper elevation={3} sx={{ overflowX: 'scroll' }}>
        <TextWithPadding>Actuals</TextWithPadding>
        <TableActualsForecasts
          data={actualsData || []}
          name="actualsList"
          tab={tab}
          assetId={router.query.id}
        />
      </Paper>
    </>
  );
};

export default Report;